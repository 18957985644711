import React, { SetStateAction, useContext, useState } from 'react';
import { MovimentazioneProductVariantViewModel, MovimentazioneViewModel, Movimentazione_LOCAL, FatturaViewModel, ProdottoEServizio } from '../../Model/MovimentazioneModel';
import { RenderDetail, DataModalConfirm, QueryObject } from '../../Model/CommonModel';
import { arrayIsNullOrEmpty } from '../../Hook/Common/Utility';
import { RootState, rootStore } from '../../Redux/Store/rootStore';
import localforage from 'localforage';
import { useEffect } from 'react';
import { DarkTheme } from '../../App';
import { SelectGridType } from '../../Hook/Grid/GridRender';
import { SelectFilterTypeGrid } from '../../Hook/Grid/GridRender';
import { useSelector } from 'react-redux';
import { useFilterTableCrud } from '../../Hook/Grid/useFilterTableCrud';
import { GetMovimentazioneViewModel, GetFilterColumnMovimentazioneViewModel } from '../../Redux/Action/Movimentazione/MovimentazioneGridAction';
import { Modal } from 'react-bootstrap';
import MovimentazioneVariant from '../Component/Variant/MovimentazioneVariant';
import ButtonActionIcon from '../../Components/Various/ButtonActionIcon';
import { Label } from 'reactstrap';
import MaterialIcon from 'react-google-material-icons'
import TripModal from '../Trip/TripModal';
import { color } from 'd3';
import { OrdineDiCaricoViewModel, ProductInTripViewModel, TripViewModel, TripViewModelResource } from '../../Model/TripModels';
import ButtonActionIconGoogleIcon from '../../Components/Various/ButtonActionIconGoogleIcon';
import { formatTime } from '../../Hook/Common/TimeAndDate';
import OrdineCarico from '../Component/Trip/OrdineCarico';
import FatturaComponent, { calcolaRiepilogoIVA } from '../Component/Fattura/FatturaComponent';
import { GetAziendaViewModel } from '../../Redux/Action/Azienda/AziendaGridAction';
import { MovimentazionePaga } from '../../Redux/Action/Movimentazione/MovimentazioneEditAction';
import ProformaModal from '../Proforma/ProformaModal';
import { ProformaViewModel, ProformaViewModelResource } from '../../Model/ProformaModels';
import MovimentazioneComponent from '../Component/Movimentazione/MovimentazioneComponent';

interface Props {
  action: {
    setConfirm(data: DataModalConfirm): any
    setQuery(query: SetStateAction<QueryObject> | undefined): any
    Edit(id: string | undefined): any;
    onDelete(id: string | undefined): any;

  }
  isMobile: boolean;
  renderGrid: RenderDetail[] | undefined;
  data: MovimentazioneViewModel[] | undefined
  query: QueryObject
  downloadedList: number[]
}

const MovimentazioneGrid: React.FC<Props> = (props) => {

  const darkTheme = useContext(DarkTheme)

  const getFiltersData = (state: RootState) => state.MovimentazioneGridReducer.filter;
  let filterData = useSelector(getFiltersData);

  const {
    filtriAttivi,
    resetFilter,
    closeAll,
    setDateToChildren,
    orderBy,
    resetFilterDate,
    getFilters,
    updateCount,
    getFiltriAttivi,
    count,
    checkFilterinValue,
    checkFilterDateinValue,
    isVisibleFiltriString,
    setIsVisibleFiltriString,
    getFiltriAttiviSearch
  } = useFilterTableCrud<QueryObject>(props.action.setQuery, GetFilterColumnMovimentazioneViewModel, props.query);

  const Aziende = (state: RootState) => state.AziendaGridReducer.gridResult;
  let AziendeResource = useSelector(Aziende);


  useEffect(() => {
    GetAziendaViewModel({});
  }, [])


  const thAction = { orderBy };
  const actionFilterCheckbox = { closeAll, updateCount, getFiltriAttivi, orderBy, getFilters, checkFilter: checkFilterinValue, settingVisibility: setIsVisibleFiltriString, resetFilter: resetFilter };
  const actionFilterSearch = { closeAll, updateCount, getFiltriAttiviSearch, orderBy };
  const actionFilterDate = { setDateToChildren, checkFilter: checkFilterDateinValue, settingVisibility: setIsVisibleFiltriString, resetFilter: resetFilterDate };
  const thActionDate = { orderBy };
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const [isVisibleModalTrip, setIsVisibleModalTrip] = useState(false)
  const [isVisibleModalOrdineCarico, setIsVisibleModalOrdineCarico] = useState(false)
  const [isVisibleModalFattura, setIsVisibleModalFattura] = useState(false)
  const closeModal = () => { setIsVisibleModal(false); setIsVisibleModalTrip(false); setIsVisibleModalOrdineCarico(false); setIsVisibleModalFattura(false) };
  const [variant, setVariant] = useState<MovimentazioneProductVariantViewModel[]>([])
  const [ViewTrip, setViewTrip] = useState<string>("")
  const [ViewProduct, setViewProduct] = useState<string>("")

  const [OrdineDiCaricoViewModel, setOrdineDiCaricoViewModel] = useState<OrdineDiCaricoViewModel | null>(null)
  const [FatturaModel, setFatturaModel] = useState<FatturaViewModel | null>(null)
  const [fatturaFileName, setFatturaFileName] = useState<string | null>(null)

  const [TripModel, setTripModel] = useState<TripViewModelResource | null>(null)
  const showOrdineDiCaricoModal = (item: TripViewModel) => {
    let ordineDiCarico = {
      dettagli: item.productInTripViewModels?.map(x => { return { colli: x.quantity / (x.colliXProdotto ?? 1), quantita: x.quantity, tipo: x.productName } }),
      intestazione: { carico: item.origin, data: formatTime(item.startTime ?? new Date()), fornitore: item.destination }
    } as OrdineDiCaricoViewModel
    setOrdineDiCaricoViewModel(ordineDiCarico);
    setIsVisibleModalOrdineCarico(true);
  }
  const CreateTrip = async (data: MovimentazioneViewModel) => {
    let azienda = AziendeResource?.items?.find(x => x.id === data.aziendaId);
    let aziendaSeller = AziendeResource?.items?.find(x => x.id === data.aziendaSellerId);

    let trip = {
      productInTripViewModels: data.movimentazioneProductVariant.map(x => { return { productVariantId: x.productVariantId, productName: `${x.productVariant.name} ${x.productVariant.size} ${x.productVariant.azienda}`, quantity: x.quantity, azienda: x.productVariant.azienda, colliXProdotto: x.productVariant.prodottiXCollo === 0 ? 1 : x.productVariant.prodottiXCollo ?? 1 } as ProductInTripViewModel; }),
      movimentazioneId: data.id,
      destination: azienda?.location,
      origin: aziendaSeller?.location,
      aziendaDestinazione: data.azienda,
      aziendaOrigine: data.azienda, aziendaDestinazioneId: data.aziendaId,
      aziendaOrigineId: data.aziendaSellerId,
      startTime: new Date(),
    } as TripViewModelResource;
    setTripModel(trip);
    setIsVisibleModalTrip(true);
  }
  const CreateFattura = async (mov) => {
    let azienda = AziendeResource?.items?.find(x => x.id === mov.aziendaId);
    let aziendaSeller = AziendeResource?.items?.find(x => x.id === mov.aziendaSellerId);

    const aliquotaIVA = 22; // Assumiamo una IVA standard del 22%

    // Mappatura delle informazioni del prodotto con IVA calcolata
    const prodottiEServizi = mov.movimentazioneProductVariant.map((pv) => {
      const importo = pv.quantity * pv.price;
      const importoIVA = importo * aliquotaIVA / 100;
      let prodPerCollo = (pv.productVariant.prodottiXCollo ?? 1) == 0 ? 1 : (pv.productVariant.prodottiXCollo ?? 1);
      return {
        descrizione: pv.productVariantName + " " + pv.productVariant.size || "Descrizione non disponibile",
        quantita: pv.quantity,
        prezzoUnitario: pv.price,
        importo: importo,
        iVA: importoIVA,
        aliquotaIVA: aliquotaIVA,
        colli: pv.quantity / prodPerCollo,
        prodottoXColli: prodPerCollo,

      } as ProdottoEServizio;
    });

    // Calcoli per il riepilogo IVA e il calcolo della fattura
    const imponibileProdotti = prodottiEServizi.reduce((sum, current) => sum + current.importo, 0);
    const totaleIVA = prodottiEServizi.reduce((sum, current) => sum + current.iVA, 0);
    const totaleDocumento = imponibileProdotti;

    let fattura: FatturaViewModel = {
      numeroFattura: mov.id.toString(),
      dataFattura: formatTime(mov.movimantazioneDate ?? new Date()) ?? "",
      totaleFattura: totaleDocumento,
      prodottiEServizi: prodottiEServizi,
      hidePrice:false,
      fornitore: {
        nome: aziendaSeller?.name || "",
        pIVA: aziendaSeller?.piva || "",
        cF: "", //aziendaSeller?.cf ||
        indirizzo: aziendaSeller?.location || "",
        email: aziendaSeller?.email || "",
        telefono: aziendaSeller?.phone || "",
        aziendaId: aziendaSeller?.id,

      },
      cliente: {
        nome: azienda?.name || "",
        pIVA: azienda?.piva || "",
        cF: "", // azienda?.cf
        indirizzo: azienda?.location || "",
        telefono: azienda?.phone || "",
        email: azienda?.email || "",
        aziendaId: azienda?.id,
      },
      pagamento: {
        metodo: mov.paymentMethod || "",
        banca: mov.bankName || "",
        iBAN: mov.iban || "",
        bIC_SWIFT: mov.bicSwift || "",
        dataScadenza: "",
        importoPagamento: mov.paymentAmount || 0,
      },
      riepilogoIVA: {
        aliquotaIVA: aliquotaIVA,
        natura: "", // Definire la natura dell'IVA se applicabile
        normativa: "", // Definire la normativa dell'IVA se applicabile
        imponibile: imponibileProdotti - totaleIVA,
        imposta: totaleIVA,
      },
      calcoloFattura: {
        imponibileProdotti: imponibileProdotti - totaleIVA,
        totaleIVA: totaleIVA,
        totaleDocumento: totaleDocumento,
        nettoAPagare: totaleDocumento,
      }
    };
    setFatturaFileName(mov.numeroFattura)
    fattura = calcolaRiepilogoIVA(fattura);
    setFatturaModel(fattura);
    setIsVisibleModalFattura(true);
  };
  const ShowVariant = (data: MovimentazioneProductVariantViewModel[]) => {
    setVariant(data);
    setIsVisibleModal(true);
  }

  const Paga = async (id: string) => {
    
    MovimentazionePaga(id).then(x => GetMovimentazioneViewModel(props.query))
  }

  return (
    <div className="row mx-0 col-12 p-0 d-flex justify-content-center">
      <Modal
        show={isVisibleModal}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        size="xl"
        onBackdropClick={closeModal}
      >
        <Modal.Header className="d-flex justify-content-center">
          <div className="w-100  px-0 mb-2">
            <div className="m-3 d-flex justify-content-between">
              <h4>Prodotti</h4>
              <ButtonActionIcon
                icon={require("../../img/close.png")}
                action={() => closeModal()}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <>   {variant?.map((x, i) =>

            <MovimentazioneVariant key={"daii" + i} model={x} productName={x.productVariant.name} >
              <div className='row m-2'>
                <div className='col-12'><p><b>Price: </b>{x.price}</p></div>
                <div className='col-12'><p><b>Quantity: </b>{x.quantity}</p></div>
              </div>

            </MovimentazioneVariant >
          )}  </>
        </Modal.Body>
      </Modal>

      <Modal show={isVisibleModalTrip} onHide={closeModal} backdrop="static" keyboard={false} size="xl">
        <Modal.Header className="d-flex justify-content-center">
          <div className="col-12 px-0 mb-2">
            <div className="col-12 mt-3">
              <h4>Aggiungi Viaggio</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <TripModal action={{ closeModal: closeModal }} data={TripModel} edit={false} isVisibleModal={isVisibleModalTrip} />
        </Modal.Body>
      </Modal>

      <Modal show={isVisibleModalOrdineCarico} onHide={closeModal} backdrop="static" keyboard={false} size="lg">
        <Modal.Header className="d-flex justify-content-center">
          <div className="col-12 px-0 mb-2">
            <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
              <h1 className="header">ORDINE DI CARICO</h1>
              <ButtonActionIcon action={closeModal} icon={require("../../img/close.png")} />

            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <OrdineCarico model={OrdineDiCaricoViewModel ?? {} as OrdineDiCaricoViewModel} />
        </Modal.Body>
      </Modal>


      <Modal show={isVisibleModalFattura} onHide={closeModal} backdrop="static" keyboard={false} fullscreen={true}>
        <Modal.Header className="d-flex justify-content-center">
          <div className="col-12 px-0 mb-2">
            <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
              <h1 className="header">Fattura</h1>
              <ButtonActionIcon action={closeModal} icon={require("../../img/close.png")} />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <ProformaModal data={{ jsonDocumentData: JSON.stringify(FatturaModel) } as ProformaViewModelResource} fileName={fatturaFileName} action={{
            closeModal: function (changed: boolean) {
              closeModal()
            },
          }} edit={false} isVisibleModal={isVisibleModalFattura} />
        </Modal.Body>
      </Modal>

      {props.isMobile ? <div className='row m-1'>
        {props.data?.map((item, i) => <MovimentazioneComponent key={item.id + i + "asdasdas"} model={item as MovimentazioneViewModel} action={{
          ShowVariant,
          CreateTrip,
          CreateFattura,
          Paga,
          removeMovimentazione(data) {
            props.action.onDelete(data.id)
          },
          edit: function (data: MovimentazioneViewModel) {
            props.action.Edit(item.id);

          }
        }}

        />
        )}
      </div> :


        <div className="col-12 mx-0">
          <table className="vma-table table   table-responsivetable-responsive table-striped w-100 minHeight">
            <thead>
              <tr className="intestazione border-top">
                {props.renderGrid?.sort((a, b) => a.order - b.order)?.filter((x) => x.show)?.map((item, i) =>
                  SelectFilterTypeGrid(
                    item.propertyName,
                    item.propertyText,
                    item.type,
                    props.query?.isSortAscending,
                    filtriAttivi,
                    actionFilterDate,
                    props.query?.sortBy,
                    filterData,
                    count,
                    actionFilterCheckbox,
                    thAction,
                    thActionDate,
                    isVisibleFiltriString,
                    actionFilterSearch
                  )
                )}
                <th className="gridHead px-0"  >
                  <div className="row mx-0 d-flex align-content-start">
                    <div className="px-2 w-100 d-flex align-items-start flex-row justify-content-between labelTh align-content-start">
                      <div className="relative">
                        <div className="filterSearch d-flex flex-row justify-content-between w-100">
                          Action
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {!arrayIsNullOrEmpty(props.data) ? props.data?.map((item, i) => (
                <tr className={`dati`} key={i}>
                  {props.renderGrid?.sort((a, b) => a.order - b.order)?.filter((x) => x.show)?.map((td, index) =>
                    td.propertyName === "trips" ?
                      <td key={td.propertyName + index + "asd" + i} className={`dati ${td.colorHeader}`} >
                        <div className="cell row">
                          {item[td.propertyName]?.map((trip: TripViewModel) =>
                            <div className='col-12' key={trip.id}>
                              <button type="button" className="btn btn-link" onClick={() => showOrdineDiCaricoModal(trip)}>
                                <img className="btnEdit" src={require("../../img/local_shipping.png")} alt="download" />
                              </button>
                              <br />
                              viaggio:<b>{trip.origin}-{trip.destination}</b> <br />
                              n° prodotti: <b>{trip.productInTripViewModels?.length}</b>
                              <ButtonActionIconGoogleIcon className="float-right" icon={ViewTrip === trip.id ? "visibility_off" : "visibility"} action={() => { setViewTrip(ViewTrip === trip.id ? "no" : trip.id) }} />
                              {ViewTrip === trip.id && trip.productInTripViewModels?.map(x =>
                                <div className='col-12' key={x.productVariantId}>
                                  <b>{x.productName}</b> quantità:<b>{x.quantity}</b>colli:<b>{(x.quantity / (x.colliXProdotto ?? 1))}</b>
                                  <hr />
                                </div>
                              )}

                              <hr />
                            </div>
                          )}

                        </div>
                      </td>
                      :
                      td.propertyName === "movimentazioneProductVariant" ?
                        <td key={td.propertyName + index + "sssqq" + i} className={`dati ${td.colorHeader}`} >
                          <div className="cell row col-12">
                            <ButtonActionIconGoogleIcon className="float-left w-100" icon={ViewProduct === item.id ? "visibility_off" : "visibility"} action={() => { setViewProduct(ViewProduct === item.id ? "no" : item.id) }} />

                            {ViewProduct === item.id && item[td.propertyName]?.map((product: MovimentazioneProductVariantViewModel, i: number) =>
                              <div className='col-12' key={product.id ?? "sss" + i}>

                                <b>{product.productVariantName} - {product.productVariant.size} </b> <br />
                                <hr />
                              </div>
                            )}
                          </div>
                        </td> : SelectGridType(item[td.propertyName], td.propertyName, td.type))}
                  <td className={`dati`}>
                    <div className="cell">
                      <div className="d-flex flex-row">
                        <button type="button" className="btn btn-link" onClick={() => { setVariant(item.movimentazioneProductVariant); setIsVisibleModal(true) }}>
                          <MaterialIcon icon="list" size={46} />
                        </button>
                        <button type="button" style={{ color: 'black' }} className="btn btn-link" onClick={() => CreateTrip(item)}>
                          <MaterialIcon icon="add_road" size={46} />
                        </button>
                        <button type="button" style={{ color: 'black' }} className="btn btn-link" onClick={() => CreateFattura(item)}>
                          <MaterialIcon icon="receipt_long" size={46} />
                        </button>
                        <button type="button" style={{ color: 'black' }} className="btn btn-link" onClick={() => Paga(item.id)}>
                          <MaterialIcon icon="payments" size={46} />
                        </button>
                        <button type="button" className="btn btn-link" onClick={() => props.action.Edit(item.id)}>
                          <img className="btnEdit" src={require("../../img/edit.png")} alt="edit" />
                        </button>
                        <button type="button" className="btn btn-link" onClick={() => props.action.onDelete(item.id)}>
                          <img className="btnEdit" src={require("../../img/delete.png")} alt="delete" />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>

              )) :
                <tr>
                  <div className="w-100 my-2">
                    <h5>La ricerca non ha prodotto risultati</h5>
                  </div></tr>
              }
            </tbody>
          </table>
        </div>
      }
    </div>
  );
}
export default MovimentazioneGrid
