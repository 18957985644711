import React, { useState, useEffect, useRef } from 'react';
import { FilterValueDto } from '../../Business/Common/CommonBusiness';
import { delayClose, toggleStateString } from '../../Hook/Common/Utility';

interface Props {
    filterData?: FilterValueDto[];
    property: string;
    overrideProperty: string;
    count: string[];
    orderAscending?: boolean;
    propertyInOrder?: string;
    FiltriAttivi: any[] | undefined;
    isVisibleFiltriString: string;
    actionFilters: {
        checkFilter: (value: string) => boolean;
        resetFilter: (value: string) => void;
        settingVisibility: (value: React.SetStateAction<string>) => void;
        closeAll(): any;
        updateCount(property: string): string[];
        getFiltriAttivi(id: string, checked: boolean, property: string): any;
        orderBy(property: string, isAscending: boolean): any;
        getFilters(property: string, text: string): any;
    };
}

const FilterMenuCheckbox: React.FC<Props> = (props) => {
    const [data, setData] = useState<FilterValueDto[] | undefined>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [showList, setShowList] = useState<boolean>(false);

    const menuRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (props.filterData !== undefined) {
            setData(props.filterData);
        }
    }, [props.filterData]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                showList &&
                containerRef.current &&
                !containerRef.current.contains(event.target as Node)
            ) {
                openClose(false);
            }
        };

        if (showList) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showList]);

    const _filtraFiltri = (text: string) => {
        setSearchText(text);
        props.actionFilters.getFilters(props.property, text);
        if (!props.count.includes(props.property)) {
            props.actionFilters.updateCount(props.property);
        }
    };

    const handleChange = (
        id: string | undefined,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const checked = event.target.checked;
        let val: any;
        switch (id) {
            case 'True':
                val = true;
                break;
            case 'False':
                val = false;
                break;
            default:
                val = id;
                break;
        }
        if (id) {
            props.actionFilters.getFiltriAttivi(
                val,
                checked,
                props.overrideProperty === '' ? props.property : props.overrideProperty
            );
        }

        if (props.FiltriAttivi?.length === 0) {
            props.actionFilters.getFilters(props.property, searchText);
        }
    };

    const openClose = (show: boolean) => {
        if (show) {
            props.actionFilters.settingVisibility(props.property);
            props.actionFilters.getFilters(props.property, '');
        } else {
            props.actionFilters.settingVisibility('');
        }
        setSearchText('');
        setShowList(show);
    };

    return (
        <div className="relative" ref={containerRef}>
            <div className="filterSearch d-flex flex-row w-100">
                <button
                    type="button"
                    className="btn btnTransaparent px-0"
                    onClick={() => openClose(!showList)}
                >
                    <img
                        src={require(`../../svg/chevron_${
                            showList ? 'up' : 'down'
                        }_Gray.png`)}
                        alt="Toggle Filter"
                    />
                </button>
                <input
                    className="filterSearch"
                    value={searchText}
                    onClick={() => {
                        if (!showList) openClose(true);
                    }}
                    onChange={(e) => _filtraFiltri(e.target.value)}
                    placeholder={
                        showList
                            ? 'Search'
                            : props.FiltriAttivi && props.FiltriAttivi.length > 0
                            ? `${props.FiltriAttivi.length} filtri attivi`
                            : 'Search'
                    }
                    type="text"
                />
                {props.FiltriAttivi && props.FiltriAttivi.length > 0 && (
                    <button
                        type="button"
                        className="btn btnTransaparent px-0"
                        onClick={() => {
                            props.actionFilters.resetFilter(props.property);
                            props.actionFilters.getFilters(props.property, '');
                        }}
                    >
                        <img
                            src={require('../../svg/close.png')}
                            height="13"
                            alt="Reset Filter"
                        />
                    </button>
                )}
            </div>
            {showList && (
                <div
                    className="col-12 row mx-0 px-2 mt-2 filterMenu absolute"
                    ref={menuRef}
                >
                    <div className="filterMenuContent w-100">
                        {data && data.length > 0 ? (
                            data
                                .sort((a, b) =>
                                    a.text?.toLowerCase() > b.text?.toLowerCase() ? 1 : -1
                                )
                                .map((item, index) => {
                                    const filtroAttivo =
                                        props.FiltriAttivi &&
                                        props.FiltriAttivi.includes(item.value);
                                    return item.text && item.value != null ? (
                                        <div
                                            className="w-100 d-flex flex-row align-items-center mx-0 mt-1"
                                            key={item.value + index}
                                        >
                                            <input
                                                type="checkbox"
                                                className="filterCheckbox mr-2"
                                                checked={filtroAttivo}
                                                onChange={(e) => handleChange(item.value, e)}
                                                id={`${props.property}-${item.value}`}
                                            />
                                            <label
                                                htmlFor={`${props.property}-${item.value}`}
                                                className="voda-regular mb-0"
                                                style={{ display: 'inline' }}
                                                dangerouslySetInnerHTML={{
                                                    __html: item.text ?? '',
                                                }}
                                            ></label>
                                        </div>
                                    ) : null;
                                })
                        ) : (
                            <label>NO VALUES</label>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FilterMenuCheckbox;
