import moment from "moment";


const getNavigatorLanguage = () => {
 	return navigator.languages[1] === "en" ? "en-gb" : navigator.languages[1];
};

import(`moment/locale/${getNavigatorLanguage()}`).then();

export function formatTime(time: Date | undefined) {
	if (time === undefined || time === null) return "";
	time = new Date(time);
	try {
		return formatDate(time);
	} catch (error) {
		console.log(error);
	}
}

function formatDate(date: Date) {
	try {
		var locale = window.navigator.languages[1] || window.navigator.language;
		moment().locale(locale);
		if (date === undefined) return "";
		var utcData = moment.utc(date).toDate();
		return moment(utcData).local().format("DD-MM-YYYY");
	} catch (error) {
		return "";
	}
}
export function formatDateWithTimeformat(date,format) {
	if (date === undefined) return;
	var locale = window.navigator.languages[1] || window.navigator.language;
	moment().locale(locale);
	var utcData = moment.utc(date).toDate();
	let value = moment(utcData).local().format( format);
	if (value === "Invalid date") {
		return "";
	} else {
		return value;
	}
}
export function formatDateWithTime(date) {
	if (date === undefined) return;
	var locale = window.navigator.languages[1] || window.navigator.language;
	moment().locale(locale);
	var utcData = moment.utc(date).toDate();
	let value = moment(utcData).local().format("L LT");
	if (value === "Invalid date") {
		return "";
	} else {
		return value;
	}
}

export function AddMonth(date: Date, numberMonth: number): Date {
	let locale = window.navigator.languages[1] || window.navigator.language;
	moment.locale(locale);

	return moment.utc(date).add(numberMonth, "M").toDate();
}