import { CustomGridRender, DateFilter, FilterValueDto, QueryObject, RenderDetail, ResultDto } from "./CommonModel";

export interface SendEmailFatturaViewModel {
  id: string;
  IdMail :string | null;
  body: string;
  email: string;
  subject: string;
  link: string;
  downloadCount: number;
  downloadCountLimit?: number | null;
  endDate: Date | null;
  startDate: Date;
  send:boolean;
  token:string|null|undefined
}
/**
 * 
 * @export
 * @interface ProformaViewModel
 */

export interface ProformaViewModel {
  id: string;
  fileName: string;
  filePath: string  ;
  jsonDocumentData: string;
  movimentazioneId: string | null;
  email: ProformaEmailViewModel[] | null;
  creationDate:Date,
  modificationDate:Date,
}

export interface ProformaViewModelQuery extends QueryObject {
  id?: string | null;
  fileName?: string[] | null;
  email?: string[] | null;
  creationDate?:DateFilter| null,
  modificationDate?:DateFilter| null,
}

export interface ProformaEmailViewModel {
  id: string;
  token: string | null;
  email: string;
  body: string;
  subject: string;
  downloadCount: number;
  downloadCountLimit: number | null;
  endDate: Date | null;
  startDate: Date;
  firstOpen: Date | null;
  lastOpen: string | null;
  sended :boolean
  sendDate:Date | null
}
  export interface ProformaViewModelResource extends ProformaViewModel{

}
// export interface ProformaViewModelQuery extends QueryObject {
//   id?: string | null;
//   fileName?: string | null;
//   downloadCount?: number | null;
//   downloadCountLimit?: number | null;
//   requiredUser?: boolean | null;
//   endDate?: DateFilter | null;
//   startDate?: DateFilter | null;
// }
/**
 * 
 * @export
 * @interface ProformaViewModelCustomGridRender
 */
export interface ProformaViewModelCustomGridRender {
  /**
   * 
   * @type {Array<RenderDetail>}
   * @memberof ProformaViewModelCustomGridRender
   */
  render?: Array<RenderDetail>;
}



/**
 * 
 * @export
 * @interface ProformaViewModelQueryResultResponse
 */
export interface ProformaViewModelQueryResultResponse {
  /**
   * 
   * @type {number}
   * @memberof ProformaViewModelQueryResultResponse
   */
  totalItems?: number;
  /**
   * 
   * @type {Array<ProformaViewModel>}
   * @memberof ProformaViewModelQueryResultResponse
   */
  items?: Array<ProformaViewModel>;
  /**
   * 
   * @type {ProformaViewModelCustomGridRender}
   * @memberof ProformaViewModelQueryResultResponse
   */
  gridRender?: ProformaViewModelCustomGridRender;
}
/**
 * 
 * @export
 * @interface ProformaViewModelQueryResultResponseResponseResult
 */
export interface ProformaViewModelQueryResultResponseResponseResult {
  /**
   * 
   * @type {boolean}
   * @memberof ProformaViewModelQueryResultResponseResponseResult
   */
  warning?: boolean;
  /**
   * 
   * @type {string}
   * @memberof ProformaViewModelQueryResultResponseResponseResult
   */
  info?: string;
  /**
   * 
   * @type {ProformaViewModelQueryResultResponse}
   * @memberof ProformaViewModelQueryResultResponseResponseResult
   */
  data?: ProformaViewModelQueryResultResponse;
}
/**
 * 
 * @export
 * @interface ProformaViewModelResource
 */
export interface ProformaViewModelResource extends ProformaViewModel {

}
/**
 * 
 * @export
 * @interface ProformaViewModelResourceResponseResult
 */
export interface ProformaViewModelResourceResponseResult {
  /**
   * 
   * @type {boolean}
   * @memberof ProformaViewModelResourceResponseResult
   */
  warning?: boolean;
  /**
   * 
   * @type {string}
   * @memberof ProformaViewModelResourceResponseResult
   */
  info?: string;
  /**
   * 
   * @type {ProformaViewModelResource}
   * @memberof ProformaViewModelResourceResponseResult
   */
  data?: ProformaViewModelResource;
}


export interface ProformaViewModelGrid {
  gridResult: ProformaListQueryResultDto | null
  filter: FilterValueDto[] | null
}


export interface ProformaModelDetail {
  dtoEdit: ProformaViewModelResource | null,
  resultDto: ResultDto<null> | null,
}

export interface ProformaCreate {
  dto: ProformaViewModelResource | null,
  resultDto: ResultDto<null> | null,
}
export interface ProformaEdit extends ProformaCreate {

}
export interface ProformaModelDownloaded {
  dtoDownload: number[],
}

export interface ProformaListQueryResultDto {
  /**
   *
   * @type {number}
   * @memberof ProformaListQueryResultDto
   */
  totalItems?: number;
  /**
   *
   * @type {Array<ProformaList>}
   * @memberof ProformaListQueryResultDto
   */
  items?: Array<ProformaViewModel>;
  /**
   *
   * @type {CustomGridRender}
   * @memberof ProformaListQueryResultDto
   */
  gridRender?: CustomGridRender;
}
export interface ProformaQueryResultDto {
  /**
   * 
   * @type {number}
   * @memberof ProformaViewModelQueryResultResponse
   */
  totalItems?: number;
  /**
   * 
   * @type {Array<ProformaViewModel>}
   * @memberof ProformaViewModelQueryResultResponse
   */
  items?: Array<ProformaViewModel>;
  /**
   * 
   * @type {ProformaViewModelCustomGridRender}
   * @memberof ProformaViewModelQueryResultResponse
   */
  gridRender?: RenderDetail[];
}


export const GET_Proforma_GRID = "GET_Proforma_GRID"
export const ADD_Proforma_GRID = "ADD_Proforma_GRID"
export const GET_Proforma_FILTERS = "GET_Proforma_FILTERS"
export const GET_Proforma_DETAIL = "GET_Proforma_DETAIL"
export const GET_EDIT_Proforma = "GET_EDIT_Proforma"
export const EDIT_Proforma = "EDIT_Proforma"
export const CREATE_Proforma = "CREATE_Proforma"
export const GET_CREATE_Proforma = "GET_CREATE_Proforma"
export const DELETE_Proforma = "DELETE_Proforma"


//#region offline definition

export const Proforma_LOCAL = "Proforma_LOCAL"
export const Proforma_FILTERS_LOCAL = "Proforma_FILTERS_LOCAL"

export const Proforma_GRIDRENDER = "Proforma_GRIDRENDER"

//#endregion
