import React from "react";
import FilterMenuDateRange from "../../Components/FormAndFilters/FilterMenuDateRange";
import FilterMenuCheckbox from "../../Components/FormAndFilters/FilterMenuCheckbox";
import FilterMenuSearchText from "../../Components/FormAndFilters/FilterMenuSearchText";
import { FilterValueDto, formatPriceWithLocale } from "../../Business/Common/CommonBusiness";
import { formatDateWithTime, formatTime } from '../Common/TimeAndDate';
import TH from '../../Components/FormAndFilters/TableCrudTH'
import { lowerFirstLetter } from "../Common/Utility";
import FilterMenuSearchNumber from "../../Components/FormAndFilters/FilterMenuSearchNumber";
import TableCrudTh from "../../Components/FormAndFilters/TableCrudTH";
// import FilterMenuIconCheckbox from "../../Components/FilterMenuIconCheckbox";
// import FilterMenuIconBooleanCheckbox from "../../Components/FilterMenuIconBooleanCheckbox";

export function SelectFilterTypeGrid(
    property: string,
    name: string,
    type: number,
    isSortAscending: boolean | undefined,
    filtriAttivi: any,
    actionFilterDate: any,
    sortBy: string | undefined,
    filterData: FilterValueDto[] | null,
    count: string[],
    actionFilterCK: any,
    thAction: any,
    thActionDate: any,
    isVisibleFiltriString: string,
    actionFilterSearch: any,
    color?: string,
    hideFilter?: boolean
) {
    property = lowerFirstLetter(property);

    const commonProps = {
        thAction: thAction,
        propertyInOrder: sortBy,
        isAscending: isSortAscending,
        spanClassName: color ?? '',
        hideHeader: false,
        overridePropertyName: name,
        propertyName: property,
        key: property,
        hideFilter: hideFilter ?? false,
        hasActiveFilters: filtriAttivi?.[property]?.length > 0,
    };

    switch (type) {
        case 3:
        case 6:
            return (
                <TableCrudTh {...commonProps}>
                    <FilterMenuDateRange
                        filter={filtriAttivi[property]}
                        property={property}
                        isVisibleFiltriString={isVisibleFiltriString}
                        actionFilters={actionFilterDate}
                    />
                </TableCrudTh>
            );
        case 5:
            return (
                <TableCrudTh {...commonProps}>
                    <FilterMenuSearchText
                        overrideProperty=""
                        property={property}
                        FiltriAttivi={filtriAttivi?.[property]}
                        actionFilters={actionFilterSearch}
                    />
                </TableCrudTh>
            );
        case 8:
            return (
                <TableCrudTh {...commonProps}>
                    <FilterMenuSearchNumber
                        overrideProperty=""
                        property={property}
                        FiltriAttivi={filtriAttivi?.[property]}
                        actionFilters={actionFilterSearch}
                    />
                </TableCrudTh>
            );
        default:
            return (
                <TableCrudTh {...commonProps}>
                    <FilterMenuCheckbox
                        filterData={filterData ?? undefined}
                        overrideProperty=""
                        property={property}
                        FiltriAttivi={filtriAttivi?.[property]}
                        count={count}
                        isVisibleFiltriString={isVisibleFiltriString}
                        actionFilters={actionFilterCK}
                    />
                </TableCrudTh>
            );
    }
}



export const rtnUrlStatusImg = (status: number) => {
    switch (status) {
        // case 1:
        //     //success
        //     return { img: require('../../svg/Completed.png'), label: "Success", value: status }
        // case 2:
        //     //failed
        //     return { img: require('../../svg/Error.png'), label: "Failed", value: status }

        // case 3:
        //     //undo
        //     return { img: require('../../svg/Undo.png'), label: "Undo", value: status }

        // case 4:
        //     //resume
        //     return { img: require('../../svg/Resume_icon.png'), label: "Resume", value: status }

        // case 5:
        //     //suspend
        //     return { img: require('../../svg/Pause_icon.png'), label: "Suspend", value: status }

        // case 6:
        //     //deleted
        //     return { img: require('../../svg/Deleted.png'), label: "Deleted", value: status }

        default:
            return { img: "", label: "", value: 0 }
    }
}

export const rtnUrlStatusBooleanImg = (target: string) => {
    let status;
    switch (target) {
        case "True":
            status = true
            break;
        default:
            status = false
            break;
    }

    switch (status) {
        // case false:
        //     //success
        //     return { img: require('../../svg/Completed.png'), label: "Completed", value: status }
        // case true:
        //     //Error
        //     return { img: require('../../svg/Pause_icon.png'), label: "Suspend", value: status }
        default:
            return { img: "", label: "", value: 0 }
    }
}

export function SelectGridType(value: any, property: string, type: number, color?: string | "", id?: number | string, valueType?: number) {

    if (property === "lastModified") {
        return <td key={property} className={`dati  ${color}`}>
            <div className="cell">
                {formatDateWithTime(value)}
            </div>
        </td>
    }
    else if (valueType === 6) {
        return <td key={property} className={`dati ${color}`} >
            <div className="cell" dangerouslySetInnerHTML={{ __html: (value === "" || value == null || value === undefined) ? "---" : formatPriceWithLocale(value) }}>
            </div>
        </td>
    }
    else if (valueType === 3) {
        return <td key={property} className={`dati ${color}`} >
            <div className="cell">
                {(value == undefined || value == null) ? "Not Specified" : formatDateWithTime(value)}
            </div>
        </td>
    }
    else if (type === 3) {
        return <td key={property} className={`dati ${color}`} >
            <div className="cell" dangerouslySetInnerHTML={{ __html: (value === "" || value == null || value === undefined) ? "---" : value }}>
            </div>
        </td>
    }
    else if (type === 6) {
        return <td key={property} className={`dati ${color}`}>
            <div className="cell">
                {(value == undefined || value == null) ? "Not Specified" : formatDateWithTime(value)}
            </div>
        </td>
    }
    else if (type === 4) {
        return <td key={property} className={`${color}`}>
            <div className="cell">
                <a href={"mailto:" + value}>{value}</a>
            </div>
        </td>
    }

    else if (value === true || value === false) {
        return <td key={property} className={`dati ${color}`}>
            <div className="cell">
                {value ? 'YES' : 'NO'}
            </div>
        </td>
    }
    else if (type === 7) {
        return <td key={`${property}${id}`} className={`dati`}>
            <div className="cell align-items-center justify-content-center">
                {value != undefined ? <img width="30" alt={rtnUrlStatusImg(value).label} title={rtnUrlStatusImg(value).label} src={rtnUrlStatusImg(value).img}></img> : null}
            </div>
        </td>
    }
    else {
        return <td key={property} className={`dati ${color}`} >
            <div className="cell" dangerouslySetInnerHTML={{ __html: (value === "" || value == null || value === undefined) ? "---" : value }}>
            </div>
        </td>
    }

}
export function SelectValueType(value: any, property: string, type: number, color?: string | "", id?: number | string) {

    if (property === "lastModified") {
        return <td key={property} className={`dati  ${color}`}>
            <div className="cell">
                {formatDateWithTime(value)}
            </div>
        </td>
    }
    else if (type === 3) {
        return <td key={property} className={`dati ${color}`} >

            <div className="cell" dangerouslySetInnerHTML={{ __html: (value === "" || value == null || value === undefined) ? "---" : value }}>
            </div>
        </td>
    }
    else if (type === 6) {
        return <td key={property} className={`dati ${color}`}>
            <div className="cell">
                {(value == undefined || value == null) ? "Not Specified" : formatDateWithTime(value)}
            </div>
        </td>
    }
    else if (type === 4) {
        return <td key={property} className={`${color}`}>
            <div className="cell">
                <a href={"mailto:" + value}>{value}</a>
            </div>
        </td>
    }
    else if (value === true || value === false) {
        return <td key={property} className={`dati ${color}`}>
            <div className="cell">
                {value ? 'YES' : 'NO'}
            </div>
        </td>
    }
    else if (type === 7) {
        return <td key={`${property}${id}`} className={`dati`}>
            <div className="cell align-items-center justify-content-center">
                {value != undefined ? <img width="30" alt={rtnUrlStatusImg(value).label} title={rtnUrlStatusImg(value).label} src={rtnUrlStatusImg(value).img}></img> : null}
            </div>
        </td>
    }
    else {
        return <td key={property} className={`dati ${color}`} >
            <div className="cell" dangerouslySetInnerHTML={{ __html: (value === "" || value == null || value === undefined) ? "---" : value }}>
            </div>
        </td>
    }

}