import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';
import { DarkTheme } from '../../App';
import { CommonValidation, formatPriceWithLocale } from '../../Business/Common/CommonBusiness';
import CanvasComponent, { CanvasComponentMethods } from '../../Components/CanvasComponent';
import { ErrorType } from '../../Components/ErrorNotification';
import InputTextComponent from '../../Components/FormAndFilters/InputTextComponent';
import { ModalSelector } from '../../Components/FormAndFilters/ModalSelector';
import RadioButtonComponent from '../../Components/FormAndFilters/RadioButtonComponent';
import SelectComponent from '../../Components/FormAndFilters/SelectComponent';
import ActionButtonIcon from '../../Components/GridSystem/ActionButtonIcon';
import ImageFromInput from '../../Components/ImageFromInput';
import Notification from '../../Components/Notification';
import ButtonActionIcon from '../../Components/Various/ButtonActionIcon';
import Icon from '../../Components/Various/Icon';
import { stringIsNullOrEmpty } from '../../Hook/Common/Utility';
import { useFormTableCrud } from '../../Hook/Grid/useFormTableCrud';
import { AziendaCreate, AziendaViewModel, GET_CREATE_Azienda } from '../../Model/Azienda';
import { ResultDto } from '../../Model/CommonModel';
import { MovimentazioneProductVariantViewModel, MovimentazioneViewModel } from '../../Model/MovimentazioneModel';
import { GET_CREATE_Prodotto, GetProdottoViewModel, ProdottoCreate, ProdottoViewModelResource } from '../../Model/ProdottoModels';
import { ProductInWarehouseViewModel } from '../../Model/ProductInWarehouse';
import { VariantViewModel } from '../../Model/VariantViewModel';
import { WarehouseViewModelQuery } from '../../Model/Warehouse';
import { GetAziendaViewModel } from '../../Redux/Action/Azienda/AziendaGridAction';
import { CreateMovimentazione } from '../../Redux/Action/Movimentazione/MovimentazioneCreateAction';
import { UpdateMovimentazione } from '../../Redux/Action/Movimentazione/MovimentazioneEditAction';
import { GetProdotto } from '../../Redux/Action/Prodotto/ProdottoGridAction';
import { GetProductSizeViewModel } from '../../Redux/Action/ProductSize/ProductSizeGridAction';
import { GetWarehouseViewModel } from '../../Redux/Action/Warehouse/WarehouseGridAction';
import { NotifyType } from '../../Redux/Reducer/NotificationReducer';
import { RootState, rootStore } from '../../Redux/Store/rootStore';
import MovimentazioneFormComponent from '../Component/Movimentazione/MovimentazioneFormComponent';
import MovimentazioneVariant from '../Component/Variant/MovimentazioneVariant';
import ProductInWarehouseComponent from '../Component/Warehouse/ProductInWarehouseComponent';
import { CodeBarReader } from '../Containers/CodeBarReader';
import ProdottoContainer from '../Containers/ProdottoContainer';
import LinearMovimentazioneVariant from '../Component/Variant/LinearMovimentazioneVariant';
import ButtonActionIconGoogleIcon from '../../Components/Various/ButtonActionIconGoogleIcon';

interface Props {
    action: {
        closeModal(changed: boolean): any | void
        onCreate?: (data: MovimentazioneViewModel | any) => void

    }
    isVisibleModal: boolean
    edit: boolean
    id?: string
}

const MovimentazioneModal: React.FC<Props> = (props) => {

    // const [prodottoTemp, setProdottoTemp] = useState<GetProdottoViewModel[]>([]);
    const [prodottoCanvasTemp, setProdottoCanvasTemp] = useState<MovimentazioneProductVariantViewModel[]>([]);
    const [newDate, setNewDate] = useState(new Date())
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [isVisibleModalGrid, setIsVisibleModalGrid] = useState(false);
    const [isVisibleModalForm, setIsVisibleModalForm] = useState(false);
    const [edit, setEdit] = useState(false);
    const [newForm, setNewForm] = useState(false);
    const [modalType, setModalType] = useState("Azienda");
    const [modalId, setModalId] = useState<string | null | undefined>(null)
    const [indexSelector, setIndexSelector] = useState<number | undefined>()
    const [notifications, setNotifications] = useState<ErrorType[]>([])
    const [azienda, setAzienda] = useState<AziendaViewModel>();
    const [aziende, setAziende] = useState<AziendaViewModel[]>([]);
    const [activeTab, setActiveTab] = useState('Grid'); // Valori possibili: 'Canvas', 'Grid', 'IA'


    const darkTheme = useContext(DarkTheme)
    const { formData, setFormData, save, changed, validation, setValidation, onChange, onChangeSelect, onChangeMultipleSelect, setChanged, inputValue, setInputValue, promiseSelect, confirmForm } = useFormTableCrud<MovimentazioneViewModel>(
        CreateMovimentazione,
        UpdateMovimentazione,
        props.action.onCreate
    );

    const [fromCanvas, setFromCanvas] = useState(false)



    const dtoEditResourceState = (state: RootState) => state.MovimentazioneCreateReducer.dto;
    let editResource = useSelector(dtoEditResourceState);

    const Warehouse = (state: RootState) => state.WarehouseGridReducer.gridResult;
    let WarehouseResource = useSelector(Warehouse);

    const ProductSize = (state: RootState) => state.ProductSizeGridReducer.gridResult;
    let ProductSizeResource = useSelector(ProductSize);

    const Aziende = (state: RootState) => state.AziendaGridReducer.gridResult;
    let AziendeResource = useSelector(Aziende);

    const Prodotto = (state: RootState) => state.ProdottoGridReducer.gridResult;
    let ProdottoResource = useSelector(Prodotto);

    const [showLinear, setshowLinear] = useState(false)



    useEffect(() => {

        if (editResource != null) {

            // editResource.movimentazioneProductVariant.forEach(element => {
            //     element.productVariant.invoicePrice = element.price; //formData?.operation === 0 ? element.productVariant.buyValue ?? 0 : element.productVariant.sellValue  ??0;
            // });
            // console.table(editResource.movimentazioneProductVariant)

            // setProdottoCanvasTemp(editResource.movimentazioneProductVariant);
            // editResource.movimentazioneProductVariant = [];
            setFormData(editResource);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editResource])

    useEffect(() => {
        GetAziendaViewModel({});
        // GetProdotto({ page:1,pageSize:10});
        GetWarehouseViewModel({} as WarehouseViewModelQuery);
        GetProductSizeViewModel({});
    }, []);

    // useEffect(() => {
    //      const fetchData = async () => {
    //       try {
    //         await SendCanvasToOcr();
    //       } catch (error) {
    //         console.error('Si è verificato un errore:', error);
    //       }
    //     };

    //     // Invocare la funzione asincrona
    //     fetchData();
    //   }, [formData?.operation]);


    const renderActiveTab = () => {
        switch (activeTab) {
            case 'Canvas':
                return CanvasArea();
            case 'Grid':
                return GridArea();
            case 'IA':
                return IAArea();
            default:
                return null;
        }
    };
    useEffect(() => {

        if (formData?.aziendaId && (formData.operation === "In" || formData.operation === 0) && prodottoCanvasTemp && prodottoCanvasTemp.length > 0) {
            var copy = [...prodottoCanvasTemp];
            copy.forEach(element => {
                element.productVariant.aziendaId = formData?.aziendaId;
                element.productVariant.azienda = formData?.azienda;
            });
            setProdottoCanvasTemp(copy);
        }

    }, [formData?.aziendaId])




    const validazioneClient = (formData: MovimentazioneViewModel | null | undefined, edit: boolean, validazioneClient: any) => {
        let copyValidation = { response: true, property: [] } as CommonValidation;
        var messages: ErrorType[] = [...notifications]

        const addInvalidProperty = (property: string) => {
            copyValidation?.property?.push(property);
            copyValidation.response = false;
        };
        var copy = { ...formData };
        copy.movimentazioneProductVariant?.forEach(x => {

            if (copy.operation === "In" || copy.operation === 0) {
                if (x.productVariant.inWarehouseViewModel === null || x.productVariant.inWarehouseViewModel === undefined || x.productVariant.inWarehouseViewModel.length === 0) {
                    messages.push({ message: `Quantità mancante su : ${x.productVariant.name} ${x.productVariant.size ?? ""}`, notifyType: NotifyType.error });
                    addInvalidProperty("inWarehouseViewModel")
                }
            } else {
                if ((x.productVariant.inWarehouseViewModel === null || x.productVariant.inWarehouseViewModel === undefined || x.productVariant.inWarehouseViewModel.length === 0) && (x.productVariant.inWarehouseViewModelDb === null || x.productVariant.inWarehouseViewModelDb === undefined || x.productVariant.inWarehouseViewModelDb.length === 0)) {
                    messages.push({ message: `Quantità mancante su : ${x.productVariant.name} ${x.productVariant.size ?? ""}`, notifyType: NotifyType.error });
                    addInvalidProperty("inWarehouseViewModel")
                }
            }

        })

        if (stringIsNullOrEmpty(copy.numeroFattura)) {
            messages.push({ message: `inserire il numero fattura o un identificativo`, notifyType: NotifyType.error });
            addInvalidProperty("numeroFattura")
        }

        setNotifications(messages);

        setValidation(copyValidation);
        return copyValidation;

    }

    const refresh = (changed: boolean) => {
        props.action.closeModal && props.action.closeModal(changed);
    };


    const afterCreate = (data: ResultDto) => {
        if (data.warning !== true) {
            props.action.closeModal && props.action.closeModal(false);
        } else {
            refresh(false);
        }

    };

    const closeModal = () => {
        setIsVisibleModal(false);
    };

    const closeModalForm = () => {
        setIsVisibleModalForm(false);
    };
    const closeModalGrid = () => {
        setIsVisibleModalGrid(false);
    };

    const openModalScan = () => {
        GetProdotto({});
        setIsVisibleModal(true);

    }



    function SetAziendaSelect(e: any): void {
        var copy = { ...formData } as MovimentazioneViewModel;
        copy.aziendaId = e.value;
        copy.azienda = e.label;
        setFormData(copy);
    }

    function SetAziendaSellerSelect(e: any): void {
        var copy = { ...formData } as MovimentazioneViewModel;
        copy.aziendaSellerId = e.value;
        copy.aziendaSeller = e.label;
        setFormData(copy);
    }
    function GetAziendaValue(): any | undefined {
        let data = AziendeResource?.items?.filter(
            (x) => x.id === formData?.aziendaId
        );
        if (data != null && data?.length > 0) {
            return { value: data[0].id ?? "", label: data[0].name ?? "" };
        }
        return undefined;
    }

    function GetAziendaSellerValue(): any | undefined {
        let data = AziendeResource?.items?.filter(
            (x) => x.id === formData?.aziendaSellerId
        );
        if (data != null && data?.length > 0) {
            return { value: data[0].id ?? "", label: data[0].name ?? "" };
        }
        return undefined;
    }



    function SetOperazione(event: any): any {
        var copy = { ...formData } as MovimentazioneViewModel;
        copy.operation = parseInt(event.target.value);
        setFormData(copy);
    }
    const canvasRef = useRef<CanvasComponentMethods>(null);

    // Metodo per attivare il metodo interno di CanvasComponent
    const SendCanvasToOcr = () => {
        canvasRef.current && canvasRef.current?.externalMethod();
    };


    function AddFromCanvas(variant: GetProdottoViewModel[], azienda: any, aziendaSeller: any, aziendaBuyer: any, date: Date | null | undefined, file: File, numeroFattura: string | null) {

        let copy: MovimentazioneViewModel = { ...formData as MovimentazioneViewModel };
        var messages: ErrorType[] = [...notifications]
        if (date != null && date !== undefined) {

            onChangeMovimentazioneDate(date);
            copy.movimantazioneDate = date;
            messages.push({ message: `Data : ${date}`, notifyType: NotifyType.success });
        }

        if (aziendaBuyer === null) {
            let rtn = { resultDto: null, dto: azienda } as AziendaCreate;
            rootStore.dispatch({ type: GET_CREATE_Azienda, payload: rtn });
            messages.push({ message: `Crea nuova azienda`, notifyType: NotifyType.warning });
            setIsVisibleModalForm(true)
            setModalId(null)
            setModalType("Azienda")
            setEdit(false)
        } else {
            copy.aziendaId = aziendaBuyer.id;
            copy.azienda = aziendaBuyer.name;
            messages.push({ message: `Azienda : ${aziendaBuyer.name}`, notifyType: NotifyType.success });
        }

        if (aziendaSeller !== null) {
            copy.aziendaSellerId = aziendaSeller.id;
            copy.aziendaSeller = aziendaSeller.name;
            messages.push({ message: `Azienda privata: ${aziendaSeller.name}`, notifyType: NotifyType.success });
        }


        let copyTemp = { ...prodottoCanvasTemp };


        copyTemp = variant.map(x => {
            messages.push({ message: `Prodotto trovato : ${x.name} ${x.size ?? ""}`, notifyType: x.variantId ? NotifyType.success : NotifyType.warning });
            if (x.invoiceQuantity && x.invoiceQuantity > 0) {
                if (formData?.operation === 0) {
                    x.inWarehouseViewModel.push({ quantity: x.invoiceQuantity ?? 0, warehouseId: WarehouseResource?.items?.[0].id, position: "local" } as ProductInWarehouseViewModel)
                } else {
                    if (x.inWarehouseViewModelDb.length === 0) {
                        x.inWarehouseViewModel.push({ quantity: x.invoiceQuantity ?? 0, warehouseId: WarehouseResource?.items?.[0].id, position: "local" } as ProductInWarehouseViewModel)
                    } else {
                        x.inWarehouseViewModelDb = x.inWarehouseViewModelDb.map((q, i) => {
                            q.quantity = i === 0 ? x.invoiceQuantity ?? 0 : 0;
                            q.warehouseId = WarehouseResource?.items?.[0].id ?? null;
                            q.position = "local";
                            return q
                        });
                    }

                }

            }
            if ((x.aziendaId === undefined || x.aziendaId === null) && (aziendaBuyer != null || aziendaBuyer != undefined)) {
                x.aziendaId = aziendaBuyer.id;
            }

            return {
                productVariant: x,
                price: x.invoicePrice,
                quantity: x.invoiceQuantity ?? 0,
                productVariantId: x.variantId,
                productId: x.prodottoId,
                productName: x.name,
                id: x.variantId,
                creaInDatabase: !(x.variantId != null && x.variantId !== undefined),


            } as MovimentazioneProductVariantViewModel
        })
        console.table(copyTemp)
        setProdottoCanvasTemp(copyTemp);
        setProdottoCanvasTemp(copyTemp);

        copy.file = file;


        copy.numeroFattura = numeroFattura;
        setFormData(copy);
        setAzienda(azienda);
        setAziende(aziende);
        setNotifications(messages);
    }


    function EditAzienda(data: AziendaViewModel) {
        setModalType("Azienda")
        setEdit(true)
        setModalId(data.id)
        setIsVisibleModalForm(true)
    }

    function AddByCanvas() {
        var messages: ErrorType[] = [...notifications];
        let copy = [...prodottoCanvasTemp];
        let valid = true;

        copy.forEach(x => {
            const inWarehouseViewModel = x.productVariant.inWarehouseViewModel;
            const inWarehouseViewModelDb = x.productVariant.inWarehouseViewModelDb;
            const isComplete = (viewModel) => viewModel.every(p => p.quantity !== undefined && p.quantity !== null && p.warehouseId && p.position);
            const isCompleteQuantity = (viewModel) => viewModel.every(p => p.quantity !== undefined && p.quantity !== null);

            // Controllo per aziendaId e sizeId
            if (x.productVariant.aziendaId === undefined || x.productVariant.aziendaId === null) {
                messages.push({ message: `Azienda mancante su: ${x.productVariant.name} ${x.productVariant.size ?? ""}`, notifyType: NotifyType.error });
                valid = false;
            }

            if (x.productVariant.invoicePrice === undefined || x.productVariant.invoicePrice === null) {
                messages.push({ message: `Prezzo mancante su: ${x.productVariant.name}`, notifyType: NotifyType.error });
                valid = false;
            }

            if (x.productVariant.sizeId === undefined || x.productVariant.sizeId === null) {
                messages.push({ message: `Formato mancante su: ${x.productVariant.name}`, notifyType: NotifyType.error });
                valid = false;
            }

            if (formData && (formData.operation === "In" || formData.operation === 0)) {
                if (!inWarehouseViewModel || inWarehouseViewModel.length === 0 || !isComplete(inWarehouseViewModel)) {
                    messages.push({ message: `Quantità mancante su: ${x.productVariant.name} ${x.productVariant.size ?? ""}`, notifyType: NotifyType.error });
                    valid = false;
                }
                if (formData.aziendaId !== undefined && formData.aziendaId !== null) {
                    x.productVariant.aziendaId = formData.aziendaId;
                }
                if (formData.azienda !== undefined && formData.azienda !== null) {
                    x.productVariant.azienda = formData.azienda;
                }
            } else {
                if ((!inWarehouseViewModel || inWarehouseViewModel.length === 0 || !isCompleteQuantity(inWarehouseViewModel)) &&
                    (!inWarehouseViewModelDb || inWarehouseViewModelDb.length === 0 || !isComplete(inWarehouseViewModelDb))) {
                    messages.push({ message: `Quantità mancante su: ${x.productVariant.name} ${x.productVariant.size ?? ""}`, notifyType: NotifyType.error });
                    valid = false;
                }

                if (x.productVariant.aziendaId === undefined || x.productVariant.aziendaId === null) {
                    messages.push({ message: `Azienda mancante su: ${x.productVariant.name} ${x.productVariant.size ?? ""}`, notifyType: NotifyType.error });
                    valid = false;
                }
            }
        });


        if (valid) {


            add(copy.map(x => {
                x.productVariant.creaInDatabase = x.creaInDatabase;
                // if (formData?.operation === 1) {
                //     x.productVariant.inWarehouseViewModel = [...x.productVariant.inWarehouseViewModel, ...x.productVariant.inWarehouseViewModelDb];
                // }
                return x.productVariant;
            }));
            setProdottoCanvasTemp([]);
        }
        setNotifications(messages);


    }
    function add(variant: GetProdottoViewModel[]): void {
        let copy = { ...formData };
        let movModel = variant.map(x => {
            return {
                productVariant: x,
                price: x.invoicePrice,
                quantity: x.inWarehouseViewModel.reduce((sum, item) => sum + (item.quantity ?? 0), 0),
                productVariantId: x.variantId,
                productName: x.name,
                productId: x.prodottoId,
                creaInDatabase: x.creaInDatabase,
                productVariantName: x.productName,
            } as MovimentazioneProductVariantViewModel
        })
        copy.movimentazioneProductVariant ??= [];
        copy.movimentazioneProductVariant = copy.movimentazioneProductVariant.concat(movModel);


        setFormData(copy as MovimentazioneViewModel);
        closeModal();
        closeModalGrid();
        setProdottoCanvasTemp([]);
    }
    function addSingle(ss: GetProdottoViewModel): void {

        let x = { ...ss };
        let copy = [...prodottoCanvasTemp];
        x.inWarehouseViewModel ??= [];
        x.inWarehouseViewModelDb ??= [];
        x.inWarehouseViewModelDbHistory ??= [];



        var data = {
            productVariant: x,
            price: formData?.operation === 0 ? x.buyValue : x.sellValue,
            quantity: x.invoiceQuantity ?? 0,
            productVariantId: x.variantId,
            productId: x.prodottoId,
            productName: x.name,
            id: x.variantId,
            creaInDatabase: !(x.variantId != null && x.variantId !== undefined),

        } as MovimentazioneProductVariantViewModel
        copy.push(data);
        setProdottoCanvasTemp(copy);

    }

    const onchangeMovimentazioneVariantCanvas = (x: MovimentazioneProductVariantViewModel, event: any, priceOrQuantity: "price" | "quantity" | "productName", i: number) => {
        let copy = [...prodottoCanvasTemp] as MovimentazioneProductVariantViewModel[];
        // let filtered = copy.findIndex(s => s.productVariant.id === x.productVariant.id);
        // if (filtered > -1) {
        if (priceOrQuantity === "price")
            copy[i].productVariant.invoicePrice = parseFloat(event.currentTarget.value);
        if (priceOrQuantity === "quantity")
            copy[i].quantity = event.currentTarget.value;
        if (priceOrQuantity === "productName")
            copy[i].productVariant.name = event.currentTarget.value;
        // }
        setProdottoCanvasTemp(copy);
    }
    const onchangeMovimentazioneVariantCanvascreaInDatabase = (i: number, value: boolean) => {
        let copy = [...prodottoCanvasTemp] as MovimentazioneProductVariantViewModel[];

        copy[i].creaInDatabase = value;

        setProdottoCanvasTemp(copy);
    }

    function GetProductSizeCanvasValue(i: number): any | undefined {
        let copy = [...prodottoCanvasTemp] as MovimentazioneProductVariantViewModel[];

        var sizeeId = copy[i].productVariant?.sizeId;
        let data = ProductSizeResource?.items?.filter(x => x.id === sizeeId);
        if (data != null && data?.length > 0) {
            return ({ value: data[0].id ?? "", label: data[0].name ?? "" })
        }
        return undefined;
    }

    function GetProductAziendaCanvasValue(i: number): any | undefined {
        let copy = [...prodottoCanvasTemp] as MovimentazioneProductVariantViewModel[];

        var sizeeId = copy[i].productVariant?.aziendaId;
        let data = AziendeResource?.items?.filter(x => x.id === sizeeId);
        if (data != null && data?.length > 0) {
            return ({ value: data[0].id ?? "", label: data[0].name ?? "" })
        }
        return undefined;
    }
    //#region warehouse
    function GetWarehouseCanvasValue(i: number, index: number): any | undefined {
        let copy = [...prodottoCanvasTemp] as MovimentazioneProductVariantViewModel[];

        var warehouseId = copy[i].productVariant.inWarehouseViewModel[index].warehouseId
        let data = WarehouseResource?.items?.filter(x => x.id === warehouseId);
        if (data != null && data?.length > 0) {
            return ({ value: data[0].id ?? "", label: data[0].name ?? "" })
        }
        return ({ value: WarehouseResource?.items?.[0].id ?? "", label: WarehouseResource?.items?.[0].name ?? "" });
    }


    function SetWarehouseCanvasSelect(e: any, i: number, index: number): void {
        let copy = [...prodottoCanvasTemp] as MovimentazioneProductVariantViewModel[];
        copy[i].productVariant.inWarehouseViewModel[index].warehouseId = e.value;
        copy[i].productVariant.inWarehouseViewModel[index].warehouse = e.label;
        setProdottoCanvasTemp(copy);
    }

    function SetProductSizeCanvasSelect(e: any, i: number): void {
        let copy = [...prodottoCanvasTemp] as MovimentazioneProductVariantViewModel[];
        copy[i].productVariant.sizeId = e.value;
        copy[i].productVariant.size = e.label;
        setProdottoCanvasTemp(copy);
    }

    function SetProductAziendaCanvasSelect(e: any, i: number): void {
        let copy = [...prodottoCanvasTemp] as MovimentazioneProductVariantViewModel[];
        copy[i].productVariant.aziendaId = e.value;
        copy[i].productVariant.azienda = e.label;
        setProdottoCanvasTemp(copy);
    }

    function addInWarehouseCanvas(x: MovimentazioneProductVariantViewModel, i: number): void {
        let copy = [...prodottoCanvasTemp] as MovimentazioneProductVariantViewModel[];

        if (copy[i].productVariant.inWarehouseViewModel) {
            copy[i].productVariant.inWarehouseViewModel.push({ quantity: 1, warehouseId: WarehouseResource?.items?.[0].id, position: "local" } as ProductInWarehouseViewModel);


        } else {
            copy[i].productVariant.inWarehouseViewModel = [];
            copy[i].productVariant.inWarehouseViewModel.push({ quantity: 1, warehouseId: WarehouseResource?.items?.[0].id, position: "local" } as ProductInWarehouseViewModel);

        }
        setProdottoCanvasTemp(copy);
    }

    const onchangeMovimentazioneVariantCanvasInWarehouse = (x: MovimentazioneProductVariantViewModel, event: any, positionOrQuantity: "position" | "quantity", i: number, index: number) => {
        let copy = [...prodottoCanvasTemp] as MovimentazioneProductVariantViewModel[];
        if (positionOrQuantity === "position")
            copy[i].productVariant.inWarehouseViewModel[index].position = event.currentTarget.value;
        if (positionOrQuantity === "quantity") {
            copy[i].productVariant.inWarehouseViewModel[index].quantity = Number.parseInt(event.currentTarget.value);
        }
        setProdottoCanvasTemp(copy);
    }
    const onchangeMovimentazioneVariantCanvasInWarehouseQuantityOut = (value: number, i: number, index: number) => {
        let copy = [...prodottoCanvasTemp] as MovimentazioneProductVariantViewModel[];
        copy[i].productVariant.inWarehouseViewModelDb[index].quantity = value;
        setProdottoCanvasTemp(copy);
    }
    const returnProduct = (product: MovimentazioneProductVariantViewModel, i: number) => {
        let copy = [...prodottoCanvasTemp] as MovimentazioneProductVariantViewModel[];

        var x = {
            name: product.productName,
            size: product.productVariant.size,
            sellValue: product.productVariant?.sellValue,
            buyValue: product.productVariant?.buyValue,
            quantita: product.quantity,
            prodottoId: product.productId,
            variantId: product.productVariantId,
            invoicePrice: copy[i].price,
            invoiceQuantity: copy[i].quantity,
            productName: product.productName,
            sizeId: product.productVariant.sizeId,
            inWarehouseViewModel: copy[i].productVariant?.inWarehouseViewModel ?? [],
            inWarehouseViewModelDb: copy[i].productVariant?.inWarehouseViewModel
                ?? [],
            inWarehouseViewModelDbHistory: copy[i].productVariant?.inWarehouseViewModel
                ?? [],
            azienda: product.productVariant?.azienda,
            aziendaId: product.productVariant?.aziendaId,
            code: product.productVariant?.code,
            id: product.id,
            image: null,
            type: null,
            creaInDatabase: false,
            prodottiXCollo: product.productVariant?.prodottiXCollo ?? 0
        } as GetProdottoViewModel;


        // if (x.invoiceQuantity && x.invoiceQuantity > 0) {
        //     if (formData?.operation === 0) {
        //         x.inWarehouseViewModel.push({ quantity: x.invoiceQuantity ?? 0, warehouseId: WarehouseResource?.items?.[0].id, position: "local" } as ProductInWarehouseViewModel)
        //     } else {
        //         if (x.inWarehouseViewModelDb.length === 0) {
        //             x.inWarehouseViewModel.push({ quantity: x.invoiceQuantity ?? 0, warehouseId: WarehouseResource?.items?.[0].id, position: "local" } as ProductInWarehouseViewModel)
        //         } else {
        //             x.inWarehouseViewModelDb = x.inWarehouseViewModelDb.map((q, i) => {
        //                 q.quantity = i === 0 ? x.invoiceQuantity ?? 0 : 0;
        //                 q.warehouseId = WarehouseResource?.items?.[0].id ?? null;
        //                 q.position = "local";
        //                 return q
        //             });
        //         }
        //     }
        // }

        var q = {
            productVariant: x,
            price: x.invoicePrice,
            quantity: x.invoiceQuantity ?? 0,
            productVariantId: x.variantId,
            productId: x.prodottoId,
            productName: x.name,
            id: x.variantId,
            creaInDatabase: !(x.variantId != null && x.variantId !== undefined),
        } as MovimentazioneProductVariantViewModel

        copy[i] = q;
        setProdottoCanvasTemp(copy);
    }

    const removeInWarehouseCanvas = (i: number, index: number) => {
        let copy = [...prodottoCanvasTemp] as MovimentazioneProductVariantViewModel[];
        copy[i].productVariant.inWarehouseViewModel.splice(index, 1);
        setProdottoCanvasTemp(copy);
    }

    //#endregion
    function onChangeMovimentazioneDate(data: Date | string): void {
        var copy = { ...formData } as MovimentazioneViewModel;
        var test = new Date(data);
        copy.movimantazioneDate = test;
        setFormData(copy);
        setNewDate(test)
    }
    // const removeVariant = (variantModel: GetProdottoViewModel) => {
    //     var copy = [...prodottoCanvasTemp];
    //     var codeIndex = copy.findIndex(x => x.id === variantModel.id);
    //     if (codeIndex > -1)
    //         copy.splice(codeIndex, 1);
    //     setProdottoTemp(copy);
    // }

    const removeMovimentazioneVariantCanvas = (i: number) => {
        var copy = [...prodottoCanvasTemp as MovimentazioneProductVariantViewModel[]];
        copy.splice(i, 1);
        setProdottoCanvasTemp(copy);
    }

    const removeMovimentazioneVariant = (i: number) => {
        var copy = { ...formData as MovimentazioneViewModel };

        copy.movimentazioneProductVariant.splice(i, 1);
        setFormData(copy);
    }
    function createFromModalSelector(data: any): void {
        //"Azienda" | "Prodotto" | "ProductSize"
        if (edit && indexSelector !== undefined) {
            switch (modalType) {
                case "Azienda":
                    let az = [...aziende];
                    az[indexSelector] = data;
                    setAziende(az);
                    break;
                case "Prodotto":
                    //todo prodotto index
                    let prod = [...prodottoCanvasTemp];
                    prod[indexSelector] = data;
                    //todo ❌
                    setProdottoCanvasTemp(prod);

                    break;
                case "ProductSize":
                    break;
                case "InWarehouse":
                    break;
            }
        } else {
            switch (modalType) {
                case "Azienda":
                    let copy: MovimentazioneViewModel = { ...formData as MovimentazioneViewModel };
                    copy.aziendaId = data.id;
                    var aziendeTemp = [...aziende];
                    aziendeTemp.push(data);
                    setAziende(aziendeTemp);
                    setFormData(copy);
                    break;
                case "Prodotto":
                    // //todo prodotto index
                    // let prod = [...prodottoTemp];
                    // prod[indexSelector] = data;
                    // setProdottoTemp(prod);

                    break;
                case "ProductSize":
                    break;
                case "InWarehouse":
                    break;
            }
        }

    }
    function Modals() {


        return <>
            <Modal
                show={isVisibleModal}
                onHide={closeModal}
                backdrop="static"
                keyboard={false}
                size="sm"
                onBackdropClick={closeModal}
            >
                <Modal.Header className="d-flex justify-content-center">
                    <div className="w-100  px-0 mb-2">
                        <div className="m-3 d-flex justify-content-between">
                            <h4>Scan</h4>
                            <ButtonActionIcon
                                icon={require("../../img/close.png")}
                                action={() => closeModal()} />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <CodeBarReader productVariant={ProdottoResource?.items ?? []} action={{ closeModal: () => closeModal(), save: add }} />
                </Modal.Body>
            </Modal>

            <Modal
                show={isVisibleModalGrid}
                onHide={closeModalGrid}
                backdrop="static"
                keyboard={false}
                // size="xl"
                fullscreen
                onBackdropClick={closeModalGrid}
            >
                <Modal.Header className="d-flex justify-content-center">
                    <div className="w-100  px-0 mb-2">
                        <div className="m-3 d-flex justify-content-between">
                            <h4>Grid</h4>
                            <ButtonActionIcon
                                icon={require("../../img/close.png")}
                                action={() => closeModalGrid()} />
                        </div>
                        <p>I prodotti selezionati verranno visualizzati in fondo alla griglia</p>
                    </div>
                </Modal.Header>
                <Modal.Body>

                    <ProdottoContainer actionGridOverride={(e) => addSingle(e)} isOnline={true} />
                </Modal.Body>
                <Modal.Footer>
                    <div className="row justify-content-start">
                        {prodottoCanvasTemp?.map((x, i) =>
                            <MovimentazioneFormComponent
                                i={i}
                                x={x}
                                removeMovimentazioneVariantCanvas={removeMovimentazioneVariantCanvas}
                                onchangeMovimentazioneVariantCanvas={onchangeMovimentazioneVariantCanvas}
                                onchangeMovimentazioneVariantCanvascreaInDatabase={onchangeMovimentazioneVariantCanvascreaInDatabase}
                                GetProductSizeCanvasValue={GetProductSizeCanvasValue}
                                ProductSizeResource={ProductSizeResource}
                                SetProductSizeCanvasSelect={SetProductSizeCanvasSelect}
                                GetProductAziendaCanvasValue={GetProductAziendaCanvasValue}
                                AziendeResource={AziendeResource}
                                SetProductAziendaCanvasSelect={SetProductAziendaCanvasSelect}
                                addInWarehouseCanvas={addInWarehouseCanvas}
                                formData={formData}
                                removeInWarehouseCanvas={removeInWarehouseCanvas}
                                onchangeMovimentazioneVariantCanvasInWarehouse={onchangeMovimentazioneVariantCanvasInWarehouse}
                                GetWarehouseCanvasValue={GetWarehouseCanvasValue}
                                WarehouseResource={WarehouseResource}
                                SetWarehouseCanvasSelect={SetWarehouseCanvasSelect}
                                onchangeMovimentazioneVariantCanvasInWarehouseQuantityOut={onchangeMovimentazioneVariantCanvasInWarehouseQuantityOut}
                                returnProduct={returnProduct}
                            />

                        )}

                        <hr />
                        <div className="col-12 justify-content-end mt-4 d-flex footerModal">
                            <button className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel" onClick={() => closeModalGrid()} type="button">
                                ANNULLA
                            </button>
                            <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" onClick={() => AddByCanvas()} type="button">
                                AGGIUNGI
                            </button>
                        </div>

                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={isVisibleModalForm} onHide={closeModal} backdrop="static" keyboard={false} size="xl">
                <Modal.Header className="d-flex justify-content-center">
                    <div className="col-12 px-0 mb-2">
                        <div className="col-12 mt-3">
                            <h4>{edit ? "Modifica " + modalType : "Aggiungi " + modalType}</h4>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <ModalSelector new={newForm} action={{ closeModal: closeModalForm, onCreate: createFromModalSelector }} edit={edit} isVisibleModal={isVisibleModalForm} id={modalId} type={modalType} />
                </Modal.Body>
            </Modal>



        </>;
    }

    function GridArea(): React.ReactNode {
        return <div className='w-100'>
            {AreaAziende()}


            <h3 className="text-center">Scegli data e identificativo</h3>
            <div className="d-flex justify-content-center">

                <div className="row">
                    <div className="col-12 col-md-6">
                        <label className="labelForm voda-bold text-uppercase w-100">
                            Data movimentazione
                            <DatePicker
                                selected={newDate}
                                onChange={(newDate, e) => {
                                    e.preventDefault();
                                    onChangeMovimentazioneDate(newDate);
                                }}
                                className="inputForm w-100"
                                minDate={new Date(1980, 0, 1)}
                                maxDate={new Date(2999, 0, 1)}
                                dateFormat="dd/MM/yyyy"
                                placeholderText={"NOT SPECIFIED"} />
                        </label>

                    </div>
                    <div className='col-12 col-md-6'>
                        <InputTextComponent type='text' value={formData?.numeroFattura} onChange={onChange} name={'numeroFattura'} title={'Numero fattura '} />
                    </div>
                </div>
            </div>
            <hr />
            <h3 className="text-center">Aggiungi i prodotti</h3>
            <div className="d-flex justify-content-center">



                {/* {formData?.aziendaId && formData?.operation !== undefined ? */}
                <div className="row">
                    {/* <div className="col-12 col-md-6"><ActionButtonIcon icon={require("../../img/barcode_scanner.png")} action={() => openModalScan()} />  <label>Da scan</label> </div> */}
                    <div className="col-12 "><ActionButtonIcon icon={require("../../img/table.png")} action={() => setIsVisibleModalGrid(true)} /><br /><label>Da grid</label> </div>

                    <div className="row justify-content-start">
                        {prodottoCanvasTemp?.map((x, i) =>
                            <MovimentazioneFormComponent
                                i={i}
                                x={x}
                                removeMovimentazioneVariantCanvas={removeMovimentazioneVariantCanvas}
                                onchangeMovimentazioneVariantCanvas={onchangeMovimentazioneVariantCanvas}
                                onchangeMovimentazioneVariantCanvascreaInDatabase={onchangeMovimentazioneVariantCanvascreaInDatabase}
                                GetProductSizeCanvasValue={GetProductSizeCanvasValue}
                                ProductSizeResource={ProductSizeResource}
                                SetProductSizeCanvasSelect={SetProductSizeCanvasSelect}
                                GetProductAziendaCanvasValue={GetProductAziendaCanvasValue}
                                AziendeResource={AziendeResource}
                                SetProductAziendaCanvasSelect={SetProductAziendaCanvasSelect}
                                addInWarehouseCanvas={addInWarehouseCanvas}
                                formData={formData}
                                removeInWarehouseCanvas={removeInWarehouseCanvas}
                                onchangeMovimentazioneVariantCanvasInWarehouse={onchangeMovimentazioneVariantCanvasInWarehouse}
                                GetWarehouseCanvasValue={GetWarehouseCanvasValue}
                                WarehouseResource={WarehouseResource}
                                SetWarehouseCanvasSelect={SetWarehouseCanvasSelect}
                                onchangeMovimentazioneVariantCanvasInWarehouseQuantityOut={onchangeMovimentazioneVariantCanvasInWarehouseQuantityOut}
                                returnProduct={returnProduct}
                            />

                        )}

                        <hr />
                        <div className="col-12 justify-content-end mt-4 d-flex footerModal">
                            <button className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel" onClick={() => closeModalGrid()} type="button">
                                ANNULLA
                            </button>
                            <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" onClick={() => AddByCanvas()} type="button">
                                AGGIUNGI
                            </button>
                        </div>

                    </div>

                </div>
                {/* : <label>compilare prima di gestire i prodotti</label>} */}
            </div>
        </div>;
    }

    function CanvasArea(): React.ReactNode {
        return <div>
            <CanvasComponent ref={canvasRef}
                azienda={formData?.operation === 0 ? formData?.azienda : formData?.aziendaSeller}
                AddFromCanvas={AddFromCanvas} operation={formData?.operation ?? 0} />
            <div className="row justify-content-start  mt-5">
                <div className='col-12'>
                    {AreaAziende()}
                    <div className='row'>
                        <div className='col-12 col-md-6'>
                            <div className="">
                                <label className="labelForm voda-bold text-uppercase w-100">
                                    Data movimentazione
                                    <DatePicker
                                        selected={newDate}
                                        onChange={(newDate, e) => {
                                            e.preventDefault();
                                            onChangeMovimentazioneDate(newDate);
                                        }}
                                        className="inputForm w-100"
                                        minDate={new Date(1980, 0, 1)}
                                        maxDate={new Date(2999, 0, 1)}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText={"NOT SPECIFIED"} />
                                </label>

                            </div>
                        </div>
                        <div className='col-12 col-md-6'>
                            <InputTextComponent type='text' value={formData?.numeroFattura} onChange={onChange} name={'numeroFattura'} title={'Numero fattura '} />
                        </div>
                        <div className='col-12 '>
                            <h4>Prodotti</h4>

                            <div className="row justify-content-start">
                                {prodottoCanvasTemp?.map((x, i) =>
                                    <MovimentazioneFormComponent
                                        i={i}
                                        x={x}
                                        removeMovimentazioneVariantCanvas={removeMovimentazioneVariantCanvas}
                                        onchangeMovimentazioneVariantCanvas={onchangeMovimentazioneVariantCanvas}
                                        onchangeMovimentazioneVariantCanvascreaInDatabase={onchangeMovimentazioneVariantCanvascreaInDatabase}
                                        GetProductSizeCanvasValue={GetProductSizeCanvasValue}
                                        ProductSizeResource={ProductSizeResource}
                                        SetProductSizeCanvasSelect={SetProductSizeCanvasSelect}
                                        GetProductAziendaCanvasValue={GetProductAziendaCanvasValue}
                                        AziendeResource={AziendeResource}
                                        SetProductAziendaCanvasSelect={SetProductAziendaCanvasSelect}
                                        addInWarehouseCanvas={addInWarehouseCanvas}
                                        formData={formData}
                                        removeInWarehouseCanvas={removeInWarehouseCanvas}
                                        onchangeMovimentazioneVariantCanvasInWarehouse={onchangeMovimentazioneVariantCanvasInWarehouse}
                                        GetWarehouseCanvasValue={GetWarehouseCanvasValue}
                                        WarehouseResource={WarehouseResource}
                                        SetWarehouseCanvasSelect={SetWarehouseCanvasSelect}
                                        onchangeMovimentazioneVariantCanvasInWarehouseQuantityOut={onchangeMovimentazioneVariantCanvasInWarehouseQuantityOut}
                                        returnProduct={returnProduct}
                                    />
                                )}

                                <hr />
                                {prodottoCanvasTemp && prodottoCanvasTemp.length ? <div className="col-12 justify-content-end mt-4 d-flex footerModal">

                                    <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" onClick={() => AddByCanvas()} type="button">
                                        AGGIUNGI
                                    </button>
                                </div> : <></>}

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>;
    }

    function IAArea(): React.ReactNode {


        return <div>
            <ImageFromInput ref={canvasRef}
                AddFromCanvas={AddFromCanvas} operation={formData?.operation ?? 0} />
            <div className="row justify-content-start mt-5">
                <div className='col-12'>
                    {AreaAziende()}
                    <div className='row'>
                        <div className=' col-md-6'>
                            <label className="labelForm voda-bold text-uppercase w-100">
                                Data movimentazione
                                <DatePicker
                                    selected={newDate}
                                    onChange={(newDate, e) => {
                                        e.preventDefault();
                                        onChangeMovimentazioneDate(newDate);
                                    }}
                                    className="inputForm w-100"
                                    minDate={new Date(1980, 0, 1)}
                                    maxDate={new Date(2999, 0, 1)}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText={"NOT SPECIFIED"} />
                            </label>
                        </div>

                        <div className='col-12 col-md-6'>
                            <InputTextComponent type='text' value={formData?.numeroFattura} onChange={onChange} name={'numeroFattura'} title={'Numero fattura '} />
                        </div>
                        <div className='col-12  '>
                            <h4>Prodotti</h4>

                            <div className="row justify-content-start">
                                {prodottoCanvasTemp?.map((x, i) =>
                                    <MovimentazioneFormComponent
                                        i={i}
                                        x={x}
                                        removeMovimentazioneVariantCanvas={removeMovimentazioneVariantCanvas}
                                        onchangeMovimentazioneVariantCanvas={onchangeMovimentazioneVariantCanvas}
                                        onchangeMovimentazioneVariantCanvascreaInDatabase={onchangeMovimentazioneVariantCanvascreaInDatabase}
                                        GetProductSizeCanvasValue={GetProductSizeCanvasValue}
                                        ProductSizeResource={ProductSizeResource}
                                        SetProductSizeCanvasSelect={SetProductSizeCanvasSelect}
                                        GetProductAziendaCanvasValue={GetProductAziendaCanvasValue}
                                        AziendeResource={AziendeResource}
                                        SetProductAziendaCanvasSelect={SetProductAziendaCanvasSelect}
                                        addInWarehouseCanvas={addInWarehouseCanvas}
                                        formData={formData}
                                        removeInWarehouseCanvas={removeInWarehouseCanvas}
                                        onchangeMovimentazioneVariantCanvasInWarehouse={onchangeMovimentazioneVariantCanvasInWarehouse}
                                        GetWarehouseCanvasValue={GetWarehouseCanvasValue}
                                        WarehouseResource={WarehouseResource}
                                        SetWarehouseCanvasSelect={SetWarehouseCanvasSelect}
                                        onchangeMovimentazioneVariantCanvasInWarehouseQuantityOut={onchangeMovimentazioneVariantCanvasInWarehouseQuantityOut}
                                        returnProduct={returnProduct}
                                    />


                                )}

                                <hr />
                                {prodottoCanvasTemp && prodottoCanvasTemp.length ? <div className="col-12 justify-content-end mt-4 d-flex footerModal">

                                    <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" onClick={() => AddByCanvas()} type="button">
                                        AGGIUNGI
                                    </button>
                                </div> : <></>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }


    function AreaAziende(): React.ReactNode {
        return (
            <>  <div className='row'>
                <div className='col-12 col-lg-6'>
                    <h3 className="text-center">Scegli l'azienda personale</h3>
                    <div className="d-flex justify-content-center">

                        <div className="row">
                            <div className="col-12">
                                <SelectComponent
                                    value={GetAziendaSellerValue()}
                                    options={AziendeResource?.items?.filter(x => x.seller === true)?.map((x) => {
                                        return { value: x.id ?? "", label: x.name ?? "" };
                                    })}
                                    onChange={(e) => SetAziendaSellerSelect(e)}
                                    name={"aziendaSellerId"}
                                    title={"Azienda personale"}
                                    isMulti={false}
                                    modal={{ modalType: "Azienda" }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-6'>
                    <h3 className="text-center">Scegli l'azienda</h3>
                    <div className="d-flex justify-content-center">

                        <div className="row">
                            <div className="col-12">
                                <SelectComponent
                                    value={GetAziendaValue()}
                                    options={AziendeResource?.items?.filter(x => x.seller === false && formData?.operation === 0 ? x.tipo === "Fornitore" : x.tipo === "Cliente")?.map((x) => {
                                        return { value: x.id ?? "", label: x.name ?? "" };
                                    })}
                                    onChange={(e) => SetAziendaSelect(e)}
                                    name={"aziendaId"}
                                    title={"Azienda"}
                                    isMulti={false}
                                    modal={{ modalType: "Azienda" }} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
                <hr /></>
        )
    }

    console.log(formData?.movimentazioneProductVariant);

    return (
        <div className="row mx-0 col-12 p-0 d-flex justify-content-center">
            <div className='row' style={{
                position: "fixed",
                right: "0",
                top: "15px",
                minHeight: "100px",
                zIndex: 1100
            }}> {notifications.map((notification, i) => (
                <Notification
                    key={i + "not"}
                    OnModal={false}
                    ShowFixed={false}
                    notifyType={notification.notifyType}
                    message={notification.message}
                    isOpen={true}
                />
            ))}
            </div>
            <form id="form" className='w-100' onChange={() => setChanged(true)}>
                <div
                    className={` mt-3 w-100  m-3 mx-0 relative h-100 justify-content-center ${darkTheme ? "dark" : ""
                        }`}
                >
                    {Modals()}
                    <div className="row w-100">
                        <div className="col-12 ">
                            <h2 className="text-center">Movimentazione</h2>
                            <h3 className="text-center">Scegli l'operazione</h3>
                            <div className="d-flex justify-content-center">

                                <div className="row">

                                    <RadioButtonComponent
                                        name='operation'
                                        options={[{ label: "IN", value: 0 }, { label: "OUT", value: 1 }]}
                                        title=''
                                        value={+(formData?.operation ?? 0)}
                                        onChange={(prop, event) => SetOperazione(event)}


                                    />

                                </div>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-center">
                                <div className="row">
                                    <div className="col-12">
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item">
                                                <a className={`nav-link ${activeTab === 'Grid' ? 'active' : ''}`} onClick={() => setActiveTab('Grid')}>Da Griglia</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={`nav-link ${activeTab === 'Canvas' ? 'active' : ''}`} onClick={() => setActiveTab('Canvas')}>Da Fattura</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={`nav-link ${activeTab === 'IA' ? 'active' : ''}`} onClick={() => setActiveTab('IA')}>IA</a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='mt-5 w-100'>
                                        {renderActiveTab()}
                                    </div>

                                    <div className="d-flex justify-content-center">

                                        <h2>Prodotti in movimentazione</h2>
                                        <h2>Totale: {
                                            formatPriceWithLocale(formData?.movimentazioneProductVariant?.reduce((acc, x) => acc + (x.price * x.quantity), 0))
                                        }</h2>
                                    </div>
                                    {/* <ButtonActionIconGoogleIcon icon='switch_left' action={() => setshowLinear(!showLinear)}></ButtonActionIconGoogleIcon> */}
                                    {formData?.movimentazioneProductVariant?.map((x, i) =>
                                        showLinear ?

                                        <LinearMovimentazioneVariant
                                        key={'daii' + i}
                                        model={x}
                                        productName={x.productVariant.name}
                                        showWarehouse={true}
                                        action={{
                                          removeVariant(data) {
                                            removeMovimentazioneVariant(i);
                                          },
                                        }}
                                      >
                                        {/* Informazioni Aggiuntive sulla Destra */}
                                        <div className="additional-info">
                                          {formData.operation === 0 ? (
                                            <div className="detail">
                                              <Icon icon={require("../../img/euro.png")} /> Prezzo acquisto: {x.price}
                                            </div>
                                          ) : (
                                            <div className="detail">
                                              <Icon icon={require("../../img/euro.png")} /> Prezzo vendita: {x.price}
                                            </div>
                                          )}
                                          <div className="detail">
                                            {x.productVariantId == null ||
                                            x.productVariantId === undefined ||
                                            x.productId == null ||
                                            x.productId === undefined ? (
                                              <span>
                                                {x.productId == null || x.productId === undefined
                                                  ? '❌ Prodotto inesistente'
                                                  : '☑️ Prodotto presente in database, variante assente'}
                                              </span>
                                            ) : (
                                              <span>✅ Presente in database</span>
                                            )}
                                          </div>
                                          {x.creaInDatabase !== undefined && (
                                            <div className="detail">
                                              Creare in Database: {x.creaInDatabase ? '✔️' : '❌'}
                                            </div>
                                          )}
                                        </div>
                                      </LinearMovimentazioneVariant>
                                      


                                            :
                                            <MovimentazioneVariant key={"daii" + i} model={x} productName={x.productVariant.name} showWarehouse={true}
                                                action={
                                                    {
                                                        removeVariant(data) {
                                                            removeMovimentazioneVariant(i)
                                                        }
                                                    }} >
                                                <>
                                                    <hr className='w-100' />
                                                    <div className='col-12 col-md-6'>{<label ><Icon icon={require("../../img/euro.png")} />{formData.operation === 0 ? "prezzo acquisto" : "prezzo vendita"} : {x.price} </label>} </div>

                                                    <div className='col-12 col-lg-6'>
                                                        {(x.productVariantId == null || x.productVariantId === undefined || x.productId == null || x.productId === undefined) ?
                                                            <label> {x.productId == null || x.productId === undefined ? "❌ prodotto inesistente" : " ☑️ prodotto presente in database variente assente"}</label>
                                                            : <label>✅ presente in database</label>}
                                                    </div>
                                                    <div className='col-12 col-lg-6'>
                                                        <label ><Icon icon={require("../../img/outline_grade_black_24dp.png")} />  {x.productVariant.size}   </label>
                                                    </div>
                                                    {x.creaInDatabase !== undefined && <div className='col-12 col-lg-6'>
                                                        <label > crea : {x.creaInDatabase ? "✔️" : "❌"}   </label>
                                                    </div>}
                                                    <div className='bg-light mx-0 col-12 p-2 d-flex justify-content-center row w-100 border rounded '>
                                                        <h4>Magazzino </h4>
                                                        {x?.productVariant.inWarehouseViewModel?.map((s, index) => (
                                                            <div className='col-12' key={"warehouse" + i + index}>
                                                                <ProductInWarehouseComponent model={s}></ProductInWarehouseComponent>
                                                                <hr />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            </MovimentazioneVariant >
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 justify-content-end mt-4 d-flex footerModal">
                        <button className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel" onClick={() => props.action.closeModal && props.action.closeModal(changed)} type="button">
                            ANNULLA
                        </button>
                        <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" onClick={() => save(formData, props.edit, validazioneClient, afterCreate)} type="button">
                            SALVA
                        </button>
                    </div>
                </div>

            </form >


        </div >
    );

}
export default MovimentazioneModal








// function MovimentazioneForm(i: number, x: MovimentazioneProductVariantViewModel, removeMovimentazioneVariantCanvas: (i: number) => void, onchangeMovimentazioneVariantCanvas: (x: MovimentazioneProductVariantViewModel, event: any, priceOrQuantity: "price" | "quantity" | "productName", i: number) => void, onchangeMovimentazioneVariantCanvascreaInDatabase: (i: number, value: boolean) => void, GetProductSizeCanvasValue: (i: number) => any | undefined, ProductSizeResource: import("c:/Users/dadec/source/repos/NeuralCane/Magazzino/Client/ClientApp/src/Model/ProductSizeModel").ProductSizeListQueryResultDto | null, SetProductSizeCanvasSelect: (e: any, i: number) => void, GetProductAziendaCanvasValue: (i: number) => any | undefined, AziendeResource: import("c:/Users/dadec/source/repos/NeuralCane/Magazzino/Client/ClientApp/src/Model/Azienda").AziendaListQueryResultDto | null, SetProductAziendaCanvasSelect: (e: any, i: number) => void, addInWarehouseCanvas: (x: MovimentazioneProductVariantViewModel, i: number) => void, formData: MovimentazioneViewModel | null | undefined, removeInWarehouseCanvas: (i: number, index: number) => void, onchangeMovimentazioneVariantCanvasInWarehouse: (x: MovimentazioneProductVariantViewModel, event: any, positionOrQuantity: "position" | "quantity", i: number, index: number) => void, GetWarehouseCanvasValue: (i: number, index: number) => any | undefined, WarehouseResource: import("c:/Users/dadec/source/repos/NeuralCane/Magazzino/Client/ClientApp/src/Model/Warehouse").WarehouseListQueryResultDto | null, SetWarehouseCanvasSelect: (e: any, i: number, index: number) => void, onchangeMovimentazioneVariantCanvasInWarehouseQuantityOut: (value: number, i: number, index: number) => void): JSX.Element {
//     return <MovimentazioneVariant key={"daii" + i} model={x} productName={x.productVariant.name} showWarehouse={true}

//         action={{
//             removeVariant(data) {
//                 removeMovimentazioneVariantCanvas(i);
//             },
//         }}
//     >
//         <>
//             {(x.productId === undefined || x.productId === null) &&
//                 <div className='col-12  '>
//                     <InputTextComponent type='text' value={x.productVariant.name} onChange={(n, e) => onchangeMovimentazioneVariantCanvas(x, e, "productName", i)} name={'productName'} title={'Nome'} />
//                 </div>}

//             <div className='col-12 col-lg-6'>
//                 <InputNumberComponent value={x.productVariant.invoicePrice ?? x.price} onChange={(n, e) => onchangeMovimentazioneVariantCanvas(x, e, "price", i)} name={'price'} title={'Price'} />
//             </div>
//             <div className='col-12 col-lg-6'>
//                 {(x.productVariantId == null || x.productVariantId === undefined || x.productId == null || x.productId === undefined) ? <CheckboxComponent
//                     value={x.creaInDatabase}
//                     onChange={() => onchangeMovimentazioneVariantCanvascreaInDatabase(i, !x.creaInDatabase)}
//                     name={"crea"}
//                     title={x.productId == null || x.productId === undefined ? "crea prodotto e variante" : "☑️ prodotto presente in database crea variante"}
//                 ></CheckboxComponent> : <label>✅ presente in database</label>}
//             </div>
//             {x.productVariantId === undefined || x.productVariantId == null ?
//                 <div className='col-12'>
//                     <SelectComponent
//                         value={GetProductSizeCanvasValue(i)}
//                         options={ProductSizeResource?.items?.map(x => { return { value: x.id ?? "", label: x.name ?? "" }; })}
//                         onChange={(e) => SetProductSizeCanvasSelect(e, i)}
//                         name={'sizeId'}
//                         title={'Size'}
//                         isMulti={false}
//                         modal={{ modalType: "ProductSize" }} />
//                 </div> : null}
//             {x.productVariantId === undefined || x.productVariantId == null ?
//                 <div className='col-12'>
//                     <SelectComponent
//                         value={GetProductAziendaCanvasValue(i)}
//                         options={AziendeResource?.items?.map(x => { return { value: x.id ?? "", label: x.name ?? "" }; })}
//                         onChange={(e) => SetProductAziendaCanvasSelect(e, i)}
//                         name={'aziendaId'}
//                         title={'Azienda'}
//                         isMulti={false}
//                         modal={{ modalType: "Azienda" }} />
//                 </div> : null}
//             {/* <InputNumberComponent value={x.quantity} onChange={(n, e) => onchangeMoivimentazioneVariantCanvas(x, e, "quantity", i)} name={'quantity'} title={'Quantity'} /> */}
//             <div className='bg-light mx-0 col-12 p-2 d-flex justify-content-center row w-100 border rounded container'>
//                 <h4>Magazzino <button className='btn btnOutline' type='button' onClick={() => addInWarehouseCanvas(x, i)}>Add</button></h4>
//                 {formData?.operation === "In" || formData?.operation === 0 ?
//                     x?.productVariant.inWarehouseViewModel?.map((s, index) => (
//                         <div className='col-12' key={"warehouse" + i + index}>
//                             <div className='d-flex justify-content-end'>
//                                 <ButtonActionIcon icon={require("../../img/delete.png")} height={15} action={() => removeInWarehouseCanvas(i, index)} />
//                             </div>
//                             <InputNumberComponent value={s.quantity} onChange={(n, e) => onchangeMovimentazioneVariantCanvasInWarehouse(x, e, "quantity", i, index)} name={'quantity'} title={'Quantity'} />
//                             <SelectComponent
//                                 value={GetWarehouseCanvasValue(i, index)}
//                                 options={WarehouseResource?.items?.map(x => { return { value: x.id ?? "", label: x.name ?? "" }; })}
//                                 onChange={(e) => SetWarehouseCanvasSelect(e, i, index)}
//                                 name={'warehouseId'}
//                                 title={'Warehouse'}
//                                 isMulti={false}
//                                 modal={{ modalType: "Warehouse" }} />
//                             <InputTextComponent value={s.position ?? "none"} onChange={(n, e) => onchangeMovimentazioneVariantCanvasInWarehouse(x, e, "position", i, index)} name={'position'} title={'Position'} type={'text'} />
//                             <hr />
//                         </div>
//                     ))
//                     :
//                     <>
//                         {x?.productVariant.inWarehouseViewModel?.filter(x => x.id === undefined || x.id == null).map((s, index) => (
//                             <div className='col-12' key={"warehouse" + i + index}>
//                                 <div className='d-flex justify-content-end'>
//                                     <ButtonActionIcon icon={require("../../img/delete.png")} height={15} action={() => removeInWarehouseCanvas(i, index)} />
//                                 </div>
//                                 <InputNumberComponent value={s.quantity} onChange={(n, e) => onchangeMovimentazioneVariantCanvasInWarehouse(x, e, "quantity", i, index)} name={'quantity'} title={'Quantity'} />

//                                 <hr />
//                             </div>
//                         ))}
//                         {x?.productVariant.inWarehouseViewModelDb?.filter(x => x.id !== undefined || x.id !== null).map((s, index) => (
//                             <div className='col-12' key={"warehouse2" + i + index}>
//                                 <ProductInWarehouseComponentOut model={s} action={{
//                                     changeQuantity(value, id) {
//                                         onchangeMovimentazioneVariantCanvasInWarehouseQuantityOut(value, i, index);
//                                     },
//                                 }}></ProductInWarehouseComponentOut>
//                             </div>
//                         ))}
//                     </>}
//             </div>
//         </>
//     </MovimentazioneVariant>;
// }
