import React, { useCallback, useContext, useState } from 'react';
import '../../Css/AziendaList.css'
import { stringIsNullOrEmpty } from '../../Hook/Common/Utility';
import { RootState } from '../../Redux/Store/rootStore';
import { useEffect } from 'react';
import { DarkTheme } from '../../App';
import { useSelector } from 'react-redux';
import { useFormTableCrud } from '../../Hook/Grid/useFormTableCrud';
import { CreateProforma } from '../../Redux/Action/Proforma/ProformaCreateAction';
import InputTextComponent from '../../Components/FormAndFilters/InputTextComponent';
import SelectComponent from '../../Components/FormAndFilters/SelectComponent';
import { CommonValidation } from '../../Business/Common/CommonBusiness';
import { UpdateProforma } from '../../Redux/Action/Proforma/ProformaEditAction';
import DatePickerComponent from '../../Components/FormAndFilters/DatePickerComponent';
import { GetVehicleViewModel } from '../../Redux/Action/Vehicle/VehicleGridAction';
import { VehicleViewModelQuery } from '../../Model/VehicleModels';
import InputPlaceComponent from '../../Components/FormAndFilters/InputPlaceComponent';
import { ProformaViewModel, ProformaViewModelResource } from '../../Model/ProformaModels';
import RadioButtonComponent from '../../Components/FormAndFilters/RadioButtonComponent';
import { GetMovimentazioneViewModel } from '../../Redux/Action/Movimentazione/MovimentazioneGridAction';
import { FatturaViewModel, MovimentazioneViewModelQuery } from '../../Model/MovimentazioneModel';
import FatturaComponent, { calcolaRiepilogoIVA } from '../Component/Fattura/FatturaComponent';
import { json } from 'd3';
import InputNumberComponent from '../../Components/FormAndFilters/InputNumberComponent';

interface Props {
  action: {
    closeModal(changed: boolean): any | void
    onCreate?: (data: ProformaViewModelResource) => void
  }
  isVisibleModal: boolean
  edit: boolean,
  data?: ProformaViewModelResource | null,
  id?: string
  fileName?: string | null,
}

const ProformaModal: React.FC<Props> = (props) => {

  const emptyFatturaViewModel: FatturaViewModel = JSON.parse(props.data?.jsonDocumentData ?? JSON.stringify({
    fornitore: {
      nome: '',
      pIVA: '',
      cF: '',
      indirizzo: '',
      telefono: '',
      email: '',
      hidePrice : false 
    },
    cliente: {
      nome: '',
      pIVA: '',
      cF: '',
      indirizzo: '',
      telefono: '',
      email: ''
    },
    prodottiEServizi: [{
      descrizione: '',
      quantita: 0,
      prezzoUnitario: 0,
      importo: 0,
      iVA: 22,
      aliquotaIVA: 0,
      colli: 0,
      prodottoXColli: 1,
    }],
    pagamento: {
      metodo: '',
      banca: '',
      iBAN: '',
      bIC_SWIFT: '',
      dataScadenza: '',
      importoPagamento: 0
    },
    riepilogoIVA: {
      aliquotaIVA: 0,
      natura: '',
      normativa: '',
      imponibile: 0,
      imposta: 0
    },
    numeroFattura: '',
    dataFattura: '',
    totaleFattura: 0,
    calcoloFattura: {
      imponibileProdotti: 0,
      totaleIVA: 0,
      totaleDocumento: 0,
      nettoAPagare: 0
    }

  }));

  useEffect(() => {
    if (props.edit === false && props.data !== null && props.data !== undefined) {
      if (props.fileName) {
        props.data.fileName = props.fileName;
      }
      setFormData(props.data);
    } 
  }, [])


 



  const validazioneClient = (formData: ProformaViewModelResource | null | undefined, edit: boolean, validazioneClient: any) => {
    let copyValidation = { response: true, property: [] } as CommonValidation;
    const addInvalidProperty = (property: string) => {
      copyValidation?.property?.push(property);
      copyValidation.response = false;
    };



    setValidation(copyValidation);
    return copyValidation;

  }

  const refresh = (changed: boolean) => {
    props.action.closeModal && props.action.closeModal(changed);
  };

  const darkTheme = useContext(DarkTheme)

  const { formData, setFormData, save, changed, validation, setValidation, onChange, onChangeDate, onChangeSelect, onChangeMultipleSelect, setChanged, inputValue, setInputValue, promiseSelect, confirmForm, onChangeForceFormData } = useFormTableCrud<ProformaViewModel>(
    CreateProforma,
    UpdateProforma,
    props.action.onCreate
  );

  const dtoEditResourceState = (state: RootState) => state.ProformaCreateReducer.dto;
  let editResource = useSelector(dtoEditResourceState);

  useEffect(() => {
    if (editResource) {
      const copy = { ...editResource };
      setFormData(copy);
    }
  }, [editResource]);



  // function GetMovimentazioneValue(): any | undefined {
  //   let data = MovimentazioneResource?.items?.filter(x => x.id === formData?.movimentazioneId);
  //   if (data != null && data?.length > 0) {
  //     return ({ value: data[0].id ?? "", label: data[0].numeroFattura ?? "" })
  //   }
  //   return undefined;
  // }
  // function SetMovimentazioneSelect(e: any): void {
  //   var copy = { ...formData } as ProformaViewModelResource;
  //   copy.movimentazioneId = e.value;
  //   // copy.movimentazioneName = e.label;
  //   setFormData(copy);
  // }



  const changeFattura = (e: FatturaViewModel) => {

    if (e.prodottiEServizi.length) {
      let copy = { ...formData } as ProformaViewModelResource;
      copy.jsonDocumentData = JSON.stringify(e);
      setFormData(copy);

    }
  }

  return (
    <div className="row mx-0 col-12 p-0 d-flex justify-content-center">
      <form id="form" className='w-100' onChange={() => setChanged(true)}>


        <div className="row mx-0 col-12 px-0">
          {
            <div className="col-12 col-md-6">
              <InputTextComponent onChange={onChange} value={formData?.fileName} name={'fileName'} title={'Nome file'} type={'text'} validation={validation} validationMessage={'fileName obbligatorio'} />
            </div>
/*
          <div className="col-12 col-md-6">
            <SelectComponent
              value={GetMovimentazioneValue()}
              options={MovimentazioneResource?.items?.map(x => { return { value: x.id ?? "", label: x.numeroFattura ?? "" } })}
              onChange={(e) => SetMovimentazioneSelect(e)}
              name={'MovimentazioneId'}
              title={'Movimentazione'}
              isMulti={false}

            />
          </div> */}
          {formData &&
            <FatturaComponent
              fattura={calcolaRiepilogoIVA(JSON.parse(formData.jsonDocumentData ?? JSON.stringify(emptyFatturaViewModel))) as FatturaViewModel}
              returnFattura={(e) => changeFattura(calcolaRiepilogoIVA(e))}
            />
          }

        </div>
        <hr />
        <div className="col-12 justify-content-end mt-4 d-flex footerModal">
          <button className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel" onClick={() => props.action.closeModal && props.action.closeModal(changed)} type="button">
            ANNULLA
          </button>
          <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" onClick={() => save(formData, props.edit, validazioneClient, (e) => { refresh(false); })} type="button">
            SALVA
          </button>
        </div>
      </form>


    </div>
  );
}
export default ProformaModal





