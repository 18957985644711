import React, { useEffect, useRef, useState } from 'react';
import CheckboxComponent from '../../../Components/FormAndFilters/CheckboxComponent';
import InputNumberComponent from '../../../Components/FormAndFilters/InputNumberComponent';
import InputTextComponent from '../../../Components/FormAndFilters/InputTextComponent';
import SelectComponent from '../../../Components/FormAndFilters/SelectComponent';
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon';
import { GetProdottoMovimentazione } from '../../../Redux/Action/Movimentazione/MovimentazioneGridAction';
import MovimentazioneVariant from '../Variant/MovimentazioneVariant';
import ProductInWarehouseComponentOut from '../Warehouse/ProductInWarehouseComponentOut';
import Icon from '../../../Components/Various/Icon';

const LinearMovimentazioneVariant = (props) => {
  const [showWarehouse, setShowWarehouse] = useState(false);

  const toggleWarehouseView = () => {
    setShowWarehouse(!showWarehouse);
  };

  // Calcola la quantità totale dai magazzini
  const totalWarehouseQuantity =
    props.model.productVariant.inWarehouseViewModelDbHistory?.reduce(
      (sum, warehouseItem) => sum + (warehouseItem.quantity || 0),
      0
    ) || 0;

  return (
    <div className="linear-movimentazione-variant">
      <div className="item-content">
        {/* Colonna Sinistra: Informazioni Principali */}
        <div className="main-info">
          <div className="item-header">
            <Icon icon={require("../../../img/bottle-wine.png")} height={20} />
            <span className="product-name">{props.productName}</span>
          </div>
          <div className="item-details">
            {props.model.productVariant.code && (
              <div className="detail">
                <Icon icon={require("../../../img/barcode.png")} />{' '}
                {props.model.productVariant.code}
              </div>
            )}
            {props.model.productVariant.quantita && (
              <div className="detail">
                <Icon icon={require("../../../img/inventory_black.png")} />{' '}
                Quantità: {props.model.productVariant.quantita}
              </div>
            )}
            {props.model.productVariant.size && (
              <div className="detail">
                <Icon icon={require("../../../img/outline_grade_black_24dp.png")} />{' '}
                Taglia: {props.model.productVariant.size}
              </div>
            )}
            {props.model.productVariant.azienda && (
              <div className="detail">
                <Icon icon={require("../../../img/store.png")} />{' '}
                Azienda: {props.model.productVariant.azienda}
              </div>
            )}
            {/* Quantità Totale dai Magazzini e Prezzo */}
            <div className="detail">
              <Icon icon={require("../../../img/inventory_black.png")} />{' '}
              Quantità Totale Magazzino: {totalWarehouseQuantity}
            </div>
            <div className="detail">
              <Icon icon={require("../../../img/euro.png")} /> Prezzo: {props.model.price}
            </div>
          </div>
        </div>

        {/* Colonna Destra: Informazioni del Flusso (Componenti Figli) */}
        <div className="flow-info">
          {props.children}
        </div>

        {/* Azioni */}
        <div className="actions">
          {props.action?.removeVariant && (
            <ButtonActionIcon
              icon={require("../../../img/delete.png")}
              height={15}
              action={() => props.action.removeVariant(props.model)}
            />
          )}
          {props.action?.addProduct && (
            <ButtonActionIcon
              icon={require("../../../img/add.png")}
              height={15}
              action={() => props.action.addProduct(props.model)}
            />
          )}
          {props.action?.editProduct && (
            <ButtonActionIcon
              icon={require("../../../img/edit.png")}
              height={15}
              action={() => props.action.editProduct(props.model)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LinearMovimentazioneVariant;
